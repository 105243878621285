.content {
  max-width: 500px;
  margin: auto;
  background: white;
  padding: 10px;
}
.remove-padding{
  .makeStyles-grid-33 {
    padding: 0px !important;
  }
  .makeStyles-grid-34 {
    padding: 0px !important;
  }
  .makeStyles-grid-35 {
    padding: 0px !important;
  }
  .makeStyles-grid-36 {
    padding: 0px !important;
  }
  .makeStyles-grid-37 {
    padding: 0px !important;
  }
  .makeStyles-grid-38 {
    padding: 0px !important;
  }
}

@media (max-width: 960px) {
  .Advice {
    margin-left: 5% !important;
    margin-right: 5% !important;
  }
}
.Advice {
  // div {
  //   display: block;
  // }
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 5%;

  .title {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .action-area {
    display: flex;
    justify-content: space-between;
    // margin-right: 23px;
    margin-top: 10px;
    margin-bottom: 10px;
    .title {
      font-size: 20px;
    }
  }

  .NQ-card-body {
    display: block !important;
  }
  .download-Icon {
    svg {
      height: 18px !important;
      margin-top: 8px;
    }
  }
  .activeStatus {
    // background: green;
    // padding: 0px 19px 1px 0px;
    // border-radius: 0;
    // border: 1px solid green;
    // border-radius: 15px;
    display: flex;
    justify-content: center;
    svg {
      fill: green;
    }
  }
  .pendingStatus {
    // background: green;
    // padding: 0px 19px 1px 0px;
    // border-radius: 0;
    // border: 1px solid green;
    // border-radius: 15px;
    display: flex;
    justify-content: center;
    svg {
      fill: rgb(255, 238, 0);
    }
  }
  .notactiveStatus {
    display: flex;
    justify-content: center;
    svg {
      fill: rgb(247, 3, 3);
    }
  }
}

@media (max-width: 960px) {
  .Advice {
    .NQ-card-body {
      display: block !important;
    }
    .Advicetext {
      font-size: 15px !important;
    }
    .Advicecategory {
      font-size: 13px !important;
    }
    .download-Icon {
      float: right;
      svg {
        height: 18px !important;
      }
    }
    .activeStatus {
      float: right;
      svg {
        margin-top: 0px !important;
        fill: green;
      }
    }
    .pendingStatus {
      float: right;
      svg {
        margin-top: 0px !important;
        fill: rgb(255, 238, 0);
      }
    }
    .notactiveStatus {
      float: right;
      svg {
        margin-top: 0px !important;
        fill: rgb(247, 3, 3);
      }
    }
  }
}

.NQ-card{
  box-shadow: rgb(18 0 59 / 12%) 1px 4px 24px, rgb(18 0 59 / 8%) 0px 4px 8px;
}