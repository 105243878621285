@media (min-width: 960px) {
  .MuiDrawer-docked > div {
    width: 80px !important;
    height: 100%;
    position: fixed;
  }
  /* .makeStyles-drawerPaper-7 {
 
  } */
  .MuiDrawer-paperAnchorLeft > div {
    width: 80px !important;
    height: 100%;
    position: fixed;
  }
}
.MuiDrawer-paper {
  z-index: 99999999999 !important;
}

.MuiListItem-root {
  width: 100% !important;
  display: flex !important;
  position: relative !important;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  justify-content: flex-start;
  text-decoration: none;
}

@media (min-width: 960px) {
  .Portfolio.ps {
    /* width: 100% !important;
    float: left; */
  }
  .Portfolio.ps > div {
    padding: 0 !important;
  }
  .Portfolio.ps > div > div {
    position: relative;
    margin: 0 !important;
    padding: 0px 10px !important;
  }
}

.mt-5 {
  margin-top: 0px i !important;
}

a {
  text-decoration: none !important;
}

.makeStyles-activePro-28 {
  color: #000 !important;
  width: 100%;
  /* bottom: 65px !important; */
  /* position: absolute !important;  */
}
.makeStyles-activePro-87 {
  color: #000 !important;
  width: 100%;
  bottom: 130px !important;
  /* position: absolute !important;  */
}
.jss29 {
  color: #000 !important;
  width: 100%;

  /* bottom: 65px;
  position: fixed; */
}

svg rect {
  /* fill: #ffffff00 !important; */
}
.makeStyles-item-16{
  display: block;
  position: static !important;
  text-decoration: none;
}
.jss16 {
  display: block;
  position: static !important;
  text-decoration: none;
}