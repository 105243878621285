.NQ-accordion-section {
    // padding-right: 32px;
    // padding-left: 32px;
    // padding-top: 24px;
    // padding-bottom: 24px;
    transition: all 150ms linear 0s;
    cursor: pointer;
    list-style: none;
    outline: none;

    .panel {
        .NQ-accordion-section-row-1 {
            cursor: pointer;
            padding: 24px 23px;
            border-bottom: 1px solid #ddd;
            display: flex;
            .dpTvjJ {
                box-sizing: border-box;
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: justify;
                justify-content: space-between;
                width: 100%;
                .sc-fujyUd {
                    box-sizing: border-box;
                    display: flex;
                    flex: 1 1 0%;

                    .eOsVek {
                        box-sizing: border-box;
                        min-width: 100px;
                    }

                    .eelevm {
                        -webkit-box-align: center;
                        align-items: center;
                        margin-left: 8px;
                        justify-content: space-between;
                        width: 100%;
                        display: flex;

                        .hukjwq {
                            margin-top: 0px;
                            margin-bottom: 0px;

                            .hSaKVG {
                                margin: 0px !important;
                                font-style: normal !important;
                                font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica,
                                    sans-serif !important;
                                font-size: 14px !important;
                                line-height: 22px !important;
                                text-align: left !important;
                                color: rgb(18, 0, 59) !important;
                                font-weight: 600 !important;
                            }
                        }

                        .eSQXjd {
                            box-sizing: border-box;
                            min-width: 100px;
                        }
                    }
                }

                .cnwwRC {
                    min-width: 80px;

                    .hSaKVG {
                        margin: 0px !important;
                        font-style: normal !important;
                        font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif !important;
                        font-size: 14px !important;
                        line-height: 22px !important;
                        text-align: left !important;
                        color: rgb(18, 0, 59) !important;
                        font-weight: 600 !important;
                    }
                }

                P {
                    margin: 0px !important;
                    font-style: normal !important;
                    font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif !important;
                    font-size: 12px !important;
                    line-height: 18px !important;
                    color: rgb(111, 112, 140) !important;
                    font-weight: 600 !important;
                    white-space: nowrap;

                    transition: all 150ms linear 0s !important;
                    cursor: pointer !important;
                    list-style: none !important;
                    outline: none !important;
                }
            }
        }

        .NQ-accordion-section-row-2 {
            cursor: pointer;
            padding: 24px 23px;
            border-bottom: 1px solid #b3aeae;

            .grVxVP {
                margin-bottom: 0px;

                .fusNkn {
                    margin-left: calc(93px);
                    padding-right: 32px;
                    padding-left: 32px;

                    .grVxVP {
                        display: flex;
                        flex-direction: column;

                        .jCasOz {
                            margin-bottom: 0px;

                            .cxKfdi {
                                padding-top: 1px;

                                .QEnpQ {
                                    display: flex;
                                    flex-flow: row wrap;
                                    margin-left: -32px;

                                    .jPBbio {
                                        box-sizing: border-box;
                                        padding-left: 32px;
                                        padding-top: 16px;
                                        -webkit-box-flex: 0;
                                        flex-grow: 0;
                                        flex-basis: auto;
                                        max-width: none;

                                        .grVxVP {
                                            display: flex;
                                            flex-direction: column;

                                            .fQhVUh {
                                                margin-bottom: 0px;

                                                .itLnTj {
                                                    margin: 0px;
                                                    font-style: normal;
                                                    font-family: Inter, -apple-system, BlinkMacSystemFont,
                                                        "Helvetica Neue", Helvetica, sans-serif;
                                                    font-size: 12px;
                                                    line-height: 18px;
                                                    text-align: left;
                                                    color: rgb(111, 112, 140);
                                                    font-weight: 600;
                                                }
                                            }

                                            .fQhVUh:last-of-type {
                                                margin-bottom: 0px;

                                                .jtfDWi {
                                                    margin: 0px;
                                                    font-style: normal;
                                                    font-family: Inter, -apple-system, BlinkMacSystemFont,
                                                        "Helvetica Neue", Helvetica, sans-serif;
                                                    font-size: 16px;
                                                    line-height: 26px;
                                                    text-align: left;
                                                    color: rgb(0, 0, 0);
                                                    font-weight: 600;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .panel {
        }
    }

    // .NQ-accordion-section-row-1:nth-last-child(1){
    //     border-top: none ;
    // }
    .NQ-accordion-section-row-1:nth-child(1) {
        // border-top: none ;
    }
}

.NQ-accordion {
    .NQ-accordion-section:nth-last-child(1) {
        display: none;
    }
}

.NQ-accordion-section-row-1:hover {
    background-color: rgb(245, 245, 248);
}

@media (max-width: 556px) {
    .sc-fujyUd {
        display: block !important;
        .eelevm {
            margin-left: 0px !important;
            display: block !important;
        }
    }
    .NQ-accordion-section-row-2 {
        .fusNkn {
            margin-left: 0px !important;
            .jPBbio {
                padding-left: 0px !important;
                padding-top: 10px !important;
                padding-bottom: 10px !important;
            }
            .p-l-mobile {
                padding-left: 20px !important;
            }
        }
    }
}

.dropdownIcon{
    display: flex;
    justify-content: end;
    /* position: absolute; */
    left: 0;
    right: 29px;
    width: 10%;
}
  
