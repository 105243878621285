.NQ-Input-Text{
    .css-1pflw1h {
        margin: 0px;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        cursor: pointer;
        border-radius: 32px;
        padding: 2px 12px;
        background-color: transparent;
        color: rgb(190, 190, 203);
    
      }
      .css-1rr4qq7 {
        flex: 1 1 0%;
      }
      .css-npjedo {
        position: relative;
        display: flex;
        flex-direction: column;
        font-weight: 600;
        width: 350px;

      }
      .css-npjedo label {
        transition-duration: 150ms;
        pointer-events: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: rgb(111, 112, 140);
        padding-left: 16px;
      }
      .css-npjedo input {
        outline: 0px;
        font-family: inherit;
        font-weight: 600;
        box-sizing: border-box;
        font-size: 16px;
        height: 64px;
        background-color: rgb(255, 255, 255);
        border-radius: 8px;
        border-width: 1px;
        border-style: solid;
        border-color: rgb(227, 225, 232);
        padding-left: 16px;
        padding-right: 16px;
        margin-top: 5px;
        margin-bottom: 5px;
      }
      .css-npjedo input:hover,
      .css-npjedo input:focus {
        border-color:rgb(96, 12, 232);
        padding-left: 15px;
        padding-right: 15px;
        border-width: 2px;
        border-color: rgb(96, 12, 232);
      }
      .css-npjedo input:not(:placeholder-shown) {
        border-color: rgb(96, 12, 232);
      }
      .css-npjedo input:disabled {
        border-color: transparent;
        background-color: rgb(239, 238, 242);
      }
      .css-npjedo input:not(:placeholder-shown) + label {
        transform: translateY(0%);
        font-size: 12px;
        top: 12px;
      }
      .css-npjedo input:not(:placeholder-shown) {
        padding-top: 24px;
      }
      .css-npjedo input::-webkit-input-placeholder {
        color: transparent;
      }
      .css-npjedo input::placeholder {
        color: transparent;
      }
      .css-1bu090c {
        position: relative;
        min-height: 101vh;
      }
      .css-vbiqt2 {
        padding-top: 32px;
        padding-bottom: 24px;
        margin-bottom: 24px;
        background: linear-gradient(354.96deg, rgb(37, 15, 90) 2.35%, rgb(47, 10, 135) 164.11%);
        margin-bottom: 24px;
        /* min-height: 250px;
          height: auto;
          max-height: max-content; */
      }
}