.notfound {
    display: flex;
    justify-content: center;
    // height: calc(100vh - 155px);
    width: 100%;
    div {
        align-self: center;
        .nomargin {
            margin: 0px !important;
        }
    }
}
