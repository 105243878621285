@media (min-width: 960px) {
}
.News {
    img {
        border: none;
        width: 100% !important;
    }
    .css-pjo951 {
        page-break-inside: avoid;
        -webkit-column-break-inside: avoid;
        break-inside: avoid;
        margin: 0 -8px;
        padding: 16px 8px;
        .css-vy2pbh {
            box-sizing: border-box;
            position: relative;
            width: 100%;
            height: 100%;
            box-shadow: rgb(18 0 59 / 12%) 0px 2px 6px, rgb(18 0 59 / 4%) 0px -1px 5px;
            border-radius: 16px;
            background-color: rgb(255, 255, 255);
            overflow: hidden;
        }
        .css-vy2pbh:hover{
            box-shadow: rgb(18 0 59 / 12%) 1px 4px 24px, rgb(18 0 59 / 8%) 0px 4px 8px;
        }
    }

    .text-EllipsisText {
        padding-left: 10px;
        padding-right: 7px;
        padding-bottom: 10px;
    }
    .text-EllipsisTitle {
        font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif !important;
        padding-left: 10px;
        padding-right: 7px;
        span {
            font-size: 24px !important;
        }
    }
}

.ismobile-News{
    .scrollbar{
        margin-left: 10px;
        margin-right: 10px;
    }
    .force-overflow{
        width: 88vw !important;
    }
    .css-pjo951{
       margin: 10px !important;
        .css-vy2pbh{
            width:  90vw !important;
        }
    }
}