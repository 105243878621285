.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    
  }
  
  .modal-main {
    position:fixed;
    // width: 80%;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    margin-bottom: 30px;
    background: #ffffff;
    border: none;
    border: 1px solid #ececec;
    border-radius: 10px;
    overflow: hidden;
    max-width: 40%;

    
    max-width: 80vw;
    pointer-events: auto;
    width: 35vw;


    padding: 20px;
  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }
  .mdhead {
    margin-top:0;
    font-size: 24px;
    font-weight: 600;
  }
  .mdactions {
    text-align: right;
  }
  .btnui{
    padding: 12px 19px;
    border-radius: 9px;
  }
  .btnuiclose{
    background-color: red;
  }
  .btnuiok{
    background-color: rgb(96, 12, 232);
    color: white;
    font-weight: bold;
  }
  .mdactionshead{
    display: flex;
    justify-content: space-between;
  }
  .mdactionsVerifyModel
  {
    text-align: right;
    display: flex;
    justify-content: flex-end;
  }