.cat-title {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    font-size: 20px;
}
@media (min-width: 960px) {
    .somethingelse {
        // .sc-hhIhEF{
        .makeStyles-grid-36 {
            padding: 0px !important;
            // }
        }
        .category {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            .NQ-web {
                margin-right: 15px;
                .NQ-card-body {
                    padding: 10px 0px !important;
                }
            }
            .NQ-web:nth-child(1) {
                // margin-left: 10px;
            }
            .cartory-border {
                border: 2px solid #600ce8;
                border-radius: 10px;
            }

            .cat-text span {
                width: 70px;
                word-break: break-all;
                line-height: 1;
                display: block !important;
                text-align: center;
            }
        }

        .css-1yjvs5a {
            display: block !important;
            margin: 0px 3.7% !important;
        }

        //5/20/2021
        //   .somethingelse {
        .firststep {
            margin: 0px 32% !important;
            .css-1ucoqeb {
                margin: 2% 0% !important;
            }
        }
        .nextstep {
            margin: 0px 33% !important;
         
        }
        //   }
        //5/20/2021
    }
}

@media (max-width: 960px) {
   
    
    .makeStyles-grid-36 {
        padding: 0px !important;
    }
    h1 {
        margin-bottom: 0 !important;
    }

    .category {
        display: flex;

        flex-wrap: wrap;
        padding-left: 10px !important;
        padding-right: 10px !important;
        justify-content: center !important;
        .NQ-web {
            margin-right: 5px;
            .NQ-card-body {
                padding: 10px 0px !important;
            }
        }
        .NQ-web:nth-child(1) {
            margin-left: 0px;
        }
        .cartory-border {
            border: 2px solid #600ce8;
            border-radius: 10px;
        }
        .cat-text span {
            width: 70px;
            word-break: break-all;
            line-height: 1;
            display: block !important;
            text-align: center;
        }
    }
    .css-1yjvs5a {
        margin: 0 3% !important;
    }
}
