.vueNow-calculation {
  display: flex;
  width: 100%;
  .vueNow-calculation-box {
    min-width: 150px;
    margin: 5px;
    padding: 20px;
    -webkit-box-align: center;
    align-items: center;
    background: rgb(255, 255, 255);
    border-color: rgb(112, 26, 235);
    border-radius: 8px;
    border-style: solid;
    color: rgb(18, 0, 59);
    cursor: pointer;
    display: block;
    -webkit-box-flex: 1;
    flex-grow: 1;
    font-size: 16px;
    font-weight: 600;
    padding-top: 25px;
    -webkit-box-pack: center;
    justify-content: center;
    margin-right: 8px;
    box-sizing: border-box;
    transition: box-shadow 150ms ease 0s;
    border-width: 2px;
    padding-right: 14px;
    padding-left: 14px;
    box-shadow: none !important;
    .lable {
      display: block;
      font-size: 15px;
      font-weight: 700;

      letter-spacing: -0.6px;
      color: #250f5a;
      text-align: center;
    }
    .count {
      display: block;
      margin: 0px 0px 16px;
      font-size: 13px;
      font-weight: 700;
      text-align: center;
      letter-spacing: -0.6px;
      color: #250f5a;
    }
  }
}

.vueNow-calculation-box:first-child {
  margin-left: 0px !important;
}
.vueNow-calculation-box:last-child {
}
