
/*! CSS Used from: https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css */

.Document {
  margin-left: 0%;
  margin-right: 0%;
  margin-top: 1.5%;
  .profile-tab {
    font-style: normal !important;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 26px !important;
    border-radius: 8px !important;
    padding: 16px 24px !important;
    display: flex !important;
    -webkit-box-align: center !important;
    align-items: center !important;
    transition: all 150ms linear 0s !important;
  
    color: rgb(111, 112, 140) !important;
    width: 100% !important;
    margin-bottom: 10px;
  }
  
  .active-tab {
    color: rgb(96, 12, 232) !important;
    background-color: rgb(227, 225, 232) !important;
  }
  .Mui-expanded {
    // border-right: 5px solid #4110b7 !important;
  }
  h1 {
    margin: 0px;
    font-size: 28px;
    font-weight: 700;
    line-height: 42px;
    color: black;
  }
  button,
  select {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }
  button {
    overflow: visible;
  }
  button,
  select {
    text-transform: none;
  }
  [type="button"],
  button {
    -webkit-appearance: button;
  }
  [type="button"]::-moz-focus-inner,
  button::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }
  [type="button"]:-moz-focusring,
  button:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
  /*! CSS Used from: Embedded */
  .css-oj9lgu {
    max-height: 0px;
    overflow: hidden;
    transition-duration: 150ms;
  }
  .css-1yekc2y {
    margin: 24px 0px 0px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: rgb(111, 112, 140);
  }
  h1 {
    margin: 0px;
    font-size: 28px;
    font-weight: 700;
    line-height: 42px;
  }
  @media (max-width: 560px) {
    h1 {
      margin: 0px;
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
    }
  }
  h3 {
    margin: 0px;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
  }
  p {
    margin: 0px;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
  }
  button {
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    font: inherit;
    margin: 0px;
    outline: none;
    overflow: visible;
    padding: 0px;
    text-transform: none;
  }
  .css-fqk8rb {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    padding-bottom: 4px;
    color: rgb(111, 112, 140);
  }
  .css-1crpfoq {
    margin: 0px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    color: rgb(0, 0, 0);
  }
  @media (max-width: 560px) {
    .css-1crpfoq {
      overflow-wrap: break-word;
    }
  }
  .css-1vj6hdk {
    margin-top: 32px;
    width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    display: none;
    color: rgb(111, 112, 140);
    font-size: 14px;
  }
  @media (max-width: 560px) {
    .css-1vj6hdk {
      margin-bottom: 32px;
      display: flex;
    }
  }
  .css-1iuj5ih {
    padding-left: 8px;
  }
  .css-19h5ycu {
    margin-bottom: 64px;
  }
  .css-10ym0te {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    // margin-bottom: 24px;
  }
  @media (max-width: 780px) {
    .css-10ym0te {
      margin-top: 20px;
      .title {
        margin-bottom: 0px !important;
      }
    }
  }
  .css-k008qs {
    display: flex;
  }
  @media (max-width: 780px) {
    .css-1kihj6h {
      display: none;
    }
  }
  @media (max-width: 780px) {
    .css-rqtpsm div:not(:first-child) {
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
    }
    .css-rqtpsm div:not(:first-child) p {
      padding: 0px;
    }
    .css-rqtpsm div:not(:first-child) span {
      font-size: 16px;
      line-height: 18px;
      margin-left: 16px;
    }
  }
  .css-rqtpsm div:not(:first-child) {
    border-top: 1px solid rgb(239, 238, 242);
    margin-top: 24px;
    padding-top: 24px;
  }
  @media (max-width: 780px) {
    .css-rqtpsm div:not(:first-child) {
      margin-top: 16px;
      padding-top: 16px;
    }
  }
  .css-153edey {
    margin: 24px 0px 0px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: rgb(111, 112, 140);
  }
  @media (max-width: 780px) {
    .css-153edey {
      display: none;
    }
  }
  .css-os7ood {
    margin-bottom: 24px;
  }
  @media (max-width: 780px) {
    .css-os7ood {
      margin-top: 24px;
    }
  }
  .css-4ew2m4 {
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
  }
  .css-4ew2m4 svg {
    margin-right: 8px;
  }
  @media (max-width: 780px) {
    .css-ea35n1 {
      margin-top: 24px;
    }
  }
  .css-ea35n1 + .css-1yekc2y {
    display: none;
  }
  @media (max-width: 780px) {
    .css-ea35n1 + .css-1yekc2y {
      display: block;
    }
  }
  .css-1ialerq {
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid !important;
  }
  .css-72enym {
    position: relative;
  }
  .css-72enym select {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: rgb(18, 0, 59);
    appearance: none;
    outline: none;
    background-color: transparent;
    border: 1px solid rgb(227, 225, 232);
    border-radius: 8px;
    padding-left: 16px;
    padding-right: 16px;
    width: 170px;
    height: 40px;
  }
  .css-72enym select:hover,
  .css-72enym select:focus {
    border-color: rgb(96, 12, 232);
    border-width: 2px;
    padding-left: 15px;
  }
  .css-1skzb8p {
    border-width: 0px 2px 2px 0px;
    border-right-style: solid;
    border-bottom-style: solid;
    border-right-color: rgb(111, 112, 140);
    border-bottom-color: rgb(111, 112, 140);
    border-image: initial;
    border-left-style: initial;
    border-left-color: initial;
    border-top-style: initial;
    border-top-color: initial;
    display: inline-block;
    height: 5.66px;
    position: absolute;
    right: 16px;
    top: calc(50% - 2.83px);
    transform: rotate(45deg) translateY(-50%);
    width: 5.66px;
  }
  .css-ltsw9u {
    display: flex;
    flex-flow: row wrap;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 1152px;
    padding-left: 12px;
    padding-right: 12px;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    align-items: stretch;
  }
  @media (max-width: 560px) {
    .css-ltsw9u {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  .css-naxvie {
    box-sizing: border-box;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    display: block;
  }
  @media (max-width: 560px) {
    .css-naxvie {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  @media (max-width: 780px) {
    .css-naxvie {
      width: 100%;
      display: block;
    }
  }
  @media (max-width: 560px) {
    .css-naxvie {
      width: 100%;
      display: block;
    }
  }
  .css-1tvpqd3 {
    display: flex;
    box-sizing: border-box;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: fit-content;
    font-weight: 600;
    border-width: 3px;
    border-style: solid;
    cursor: pointer;
    line-height: 22px;
    transition-duration: 150ms;
    height: 32px;
    min-width: 96px;
    font-size: 14px;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 16px;
    color: rgb(96, 12, 232);
    background-color: transparent;
    border-color: rgb(96, 12, 232);
    margin-left: 16px;
  }
  .css-1tvpqd3:focus {
    border-color: rgb(219, 175, 254);
    outline: none;
  }
  .css-1tvpqd3:disabled {
    cursor: not-allowed;
  }
  .css-1tvpqd3:hover {
    background-color: rgb(96, 12, 232);
    color: rgb(255, 255, 255);
  }
  .css-1tvpqd3:disabled {
    color: rgba(96, 12, 232, 0.4);
    border-color: rgba(96, 12, 232, 0.4);
  }
  .css-1dvs3yj {
    display: flex;
    box-sizing: border-box;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: fit-content;
    font-weight: 600;
    border-width: 3px;
    border-style: solid;
    border-color: transparent;
    cursor: pointer;
    line-height: 22px;
    transition-duration: 150ms;
    height: 32px;
    min-width: 96px;
    font-size: 14px;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 16px;
    color: rgb(255, 255, 255);
    background-color: rgb(96, 12, 232);
    margin-left: 16px;
  }
  .css-1dvs3yj:focus {
    border-color: rgb(219, 175, 254);
    outline: none;
  }
  .css-1dvs3yj:disabled {
    cursor: not-allowed;
  }
  .css-1dvs3yj:hover,
  .css-1dvs3yj:active {
    background-color: rgb(65, 16, 183);
  }
  .css-1dvs3yj:disabled {
    color: rgba(255, 255, 255, 0.4);
    background-color: rgba(96, 12, 232, 0.4);
  }
  .css-1efjda {
    box-sizing: border-box;
    padding-left: 12px;
    padding-right: 12px;
    width: 33.3333%;
    display: block;
  }
  @media (max-width: 560px) {
    .css-1efjda {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  @media (max-width: 780px) {
    .css-1efjda {
      width: 100%;
      display: block;
    }
  }
  @media (max-width: 560px) {
    .css-1efjda {
      width: 100%;
      display: block;
    }
  }
  .css-vy2pbh {
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    box-shadow: rgba(18, 0, 59, 0.12) 0px 2px 6px, rgba(18, 0, 59, 0.04) 0px -1px 5px;
    border-radius: 16px;
  }
  .css-4lc1dm {
    border-width: 0px 0px 1px;
    border-image: initial;
    border-style: solid;
    border-color: rgb(227, 225, 232);
    padding: 16px 32px;
  }
  .css-4lc1dm h3 {
    margin: 0px;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    color: black;
  }
  @media (max-width: 780px) {
    .css-4lc1dm {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
  .css-xqrdoy {
    padding: 32px;
  }
  @media (max-width: 780px) {
    .css-xqrdoy {
      padding: 24px;
    }
  }
  .css-lze3sy {
    margin: 0px;
    font-size: 23px;
    line-height: 46px;
    letter-spacing: -0.6px;
    font-weight: 600;
    color: rgb(0, 0, 0);
  }
  @media (max-width: 560px) {
    .css-lze3sy {
      overflow-wrap: break-word;
    }
  }
  .css-2mqgjc {
    box-sizing: border-box;
    padding-left: 12px;
    padding-right: 12px;
    width: 100%;
    display: block;
  }
  @media (max-width: 560px) {
    .css-2mqgjc {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  @media (max-width: 780px) {
    .css-2mqgjc {
      width: 100%;
      display: block;
    }
  }
  @media (max-width: 560px) {
    .css-2mqgjc {
      width: 100%;
      display: block;
    }
  }
  .css-1gvwazu {
    border-left: 4px solid transparent;
    overflow: hidden;
    padding: 24px 32px;
    transition-duration: 150ms;
  }
  @media (max-width: 560px) {
    .css-1gvwazu {
      padding: 16px 24px;
    }
  }
  .css-1gvwazu:not(:first-child) {
    border-top: 1px solid rgb(239, 238, 242);
  }
  .css-9jay18 {
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
  }
  .css-165znzt {
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    flex-grow: 1;
    width: 100%;
  }
  @media (max-width: 560px) {
    .css-165znzt {
      display: block;
    }
  }
  .css-1jqvzpx {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: rgb(111, 112, 140);
    min-width: 90px;
    width: 20%;
  }

  .text-wrap {
    width: 40% !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .w-20 {
    width: 20%;
  }

  @media (max-width: 560px) {
    .css-1jqvzpx {
      margin-bottom: 4px;
    }
  }
  .css-1vseuxq {
    margin: 0px 16px 0px 0px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: rgb(0, 0, 0);
    -webkit-box-flex: 1;
    flex-grow: 1;
  }
  @media (max-width: 560px) {
    .css-1vseuxq {
      margin-bottom: 4px;
    }
  }
  .css-18wwfoy {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: rgb(111, 112, 140);
    min-width: 100px;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
  }
  .css-18wwfoy span {
    padding-left: 4px;
  }
  .css-1kecnp1 {
    margin: 0px 16px 0px 0px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: rgb(0, 0, 0);
    min-width: 80px;
    text-align: right;
    align-self: center;
  }
  @media (max-width: 560px) {
    .css-1kecnp1 {
      margin-right: 0px;
      min-width: auto;
    }
  }
  .css-1bep9n9 {
    border-top: 1px solid rgb(227, 225, 232);
    margin-left: 90px;
    // margin-top: 24px;
    padding-top: 24px;
    display: flex;
  }
  @media (max-width: 560px) {
    .css-1bep9n9 {
      margin-left: 0px;
    }
  }
  .css-1kmimlc {
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    box-shadow: rgba(18, 0, 59, 0.12) 0px 2px 6px, rgba(18, 0, 59, 0.04) 0px -1px 5px;
    border-radius: 16px;
    margin-bottom: 24px;
  }
  .css-sdqait {
    border-left: 4px solid transparent;
    overflow: hidden;
    padding: 0 0;
    transition-duration: 150ms;
    cursor: pointer;
  }
  @media (max-width: 560px) {
    .css-sdqait {
      padding: 16px 24px;
    }
  }
  .css-sdqait:not(:first-child) {
    border-top: 1px solid rgb(239, 238, 242);
  }
  .css-jj4hon {
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
  }
  @media (max-width: 560px) {
    .css-jj4hon {
      display: block;
    }
    .css-jj4hon div:first-child {
      margin-bottom: 24px;
    }
  }
  .css-1xhj18k {
    display: flex;
    flex-direction: row;
  }
  .css-1l0z8uk {
    flex-direction: row;
  }
  .css-nb6ul0 {
    margin-right: 4px;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
  }
  .css-1g5zpcf {
    border-color: rgb(111, 112, 140);
    border-width: 1px;
    border-radius: 7px;
    height: 7px;
    width: 7px;
    background-color: rgb(244, 163, 5);
  }
  .css-1x97aoq {
    width: 4px;
    height: 1px;
    background-color: rgb(244, 163, 5);
  }
  .css-1s6qyqw {
    border-color: rgb(111, 112, 140);
    border-width: 1px;
    border-radius: 7px;
    height: 7px;
    width: 7px;
    background-color: rgb(227, 225, 232);
  }
  .css-120gsus {
    margin-right: 32px;
  }
  .css-137io4s {
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
  }
  @media (max-width: 560px) {
    .css-137io4s {
      flex-wrap: wrap;
    }
    .css-137io4s div:last-child {
      margin-top: 24px;
      width: 100%;
    }
  }
  .css-19jkesh {
    border-color: rgb(111, 112, 140);
    border-width: 1px;
    border-radius: 7px;
    height: 7px;
    width: 7px;
    background-color: rgb(8, 210, 178);
  }
  .css-1rxuejg {
    width: 4px;
    height: 1px;
    background-color: rgb(8, 210, 178);
  }

  /*  scroll working */
  .scrollbar {
    margin-left: 18px;
    margin-right: 18px;

    background: #f5f5f5;
    overflow-x: scroll;
    margin-bottom: 0;
    min-height: auto;
    padding-bottom: 10px;
  }

  .force-overflow {
    min-width: 50px;
    /* width: auto; */
    /* max-width: 270px; */
    display: contents;
  }

  #wrapper {
    text-align: center;
    width: 500px;
    margin: auto;
  }

  /*
   *  STYLE 4
   */

  #scroll-x::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(37 15 90);
    background-color: #f5f5f5;
  }

  #scroll-x::-webkit-scrollbar {
    height: 5px;
    background-color: #f5f5f5;
  }

  #scroll-x::-webkit-scrollbar-thumb {
    background-color: #4110b7;
    border: 2px solid #555555;
  }

  /* .MuiContainer-root {
    padding-left: 24px ;
  } */

  .css-13bzwy9 {
    margin-bottom: 0px !important;
  }

  .summary-panel {
    padding-bottom: 10px !important;
    background: #fff;
    border-radius: 11px;
    box-shadow: rgb(18 0 59 / 12%) 0px 2px 6px, rgb(18 0 59 / 4%) 0px -1px 5px;
  }
  .summary-panel-body {
    padding: 30px;
    /* height: auto;
      min-height: 52vh;
      max-height: 52vh;
      overflow: scroll;
      overflow-y: auto;
      overflow-x: hidden; */
  }

  .scrollbar {
    /* margin-left: 18px;
      margin-right: 18px;
   
    background: #F5F5F5;
    overflow-x: scroll;
    margin-bottom: 0;
    min-height: 16vh;
      padding-bottom: 10px; */
  }

  .force-overflow {
    min-width: 50px;
    /* width: auto; */
    /* max-width: 270px; */
    display: contents;
  }

  #wrapper {
    text-align: center;
    width: 500px;
    margin: auto;
  }

  /*
   *  STYLE 4
   */

  #scroll-y::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(37 15 90);
    background-color: #f5f5f5;
  }

  #scroll-y::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
  }

  #scroll-y::-webkit-scrollbar-thumb {
    background-color: #4110b7;
    border: 2px solid #555555;
  }

  .accordionActive {
    border-left: 5px solid #4110b7;
    background: rgb(245, 245, 248);

    .Mui-expanded {
      background: rgb(245, 245, 248);
    }
    .MuiCollapse-entered {
      background: rgb(245, 245, 248);
    }
    .makeStyles-heading-47 {
      background-color: rgb(245, 245, 248);
    }
  }
  .css-1ialerq {
    display: block !important;
  }
  .css-1skzb8p {
    right: 25px !important;
  }
  .css-72enym select {
    width: 100% !important;
  }

  .sorting-row {
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #ddd;
    background: #dddddd21;
    .MuiSvgIcon-root {
      width: 20px !important;
      fill: #4110b7 !important;
    }
  }
}

@media (max-width: 960px) {
  .Document {
    margin-left: 1.5% !important;
    margin-right: 1.5% !important;
    .css-sdqait {
      padding: 0px !important;
      // border-left: none !important;
      .w-20 {
        width: 100% !important;
      }
      .text-wrap {
        width: 100% !important;
      }
    }
    .css-1bep9n9 {
      margin-left: 10px !important;
    }
    .css-1skzb8p {
      // right: -2px !important;
      top: 18px;
    }
    .css-72enym select {
      width: 100% !important;
      margin-bottom: 10px;
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }
}

.show-cell {
  background: white;
  border-left: 1px solid lightgrey !important;
  border-right: 1px solid lightgrey !important;
  border-bottom: 1px solid lightgrey !important;
}

.show-name {
  font-weight: bold;
}

.show-presenter {
  font-style: italic;
}

.ag-body-viewport-wrapper.ag-layout-normal {
  overflow-x: scroll;
  overflow-y: scroll;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #250f5a !important; ///rgba(0,0,0,.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.search-icon {
  right: 10px !important;
  top: 11px !important;
  font-size: 5px !important;
  position: absolute !important;
  .MuiSvgIcon-root {
    height: 20px !important;
    width: 20px !important;
  }
}

.action-area {
  display: flex;
  justify-content: space-between;
  // margin-right: 23px;
  margin-top: 10px;
  margin-bottom: 10px;
  .title {
    font-size: 20px;
  }
  .action {
    display: flex;
  }
}
.title {
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
  margin-bottom: 10px;
  font-size: 20px;
}

.transaction-card {
  padding: 20px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
}
.transaction-card:hover {
  background-color: #4110b7;
  color: #fff;
  font-weight: 600;
}
.transaction-card:nth-last-child(1) {
  margin-bottom: 20px;
}

///////////////////////@at-root

@media only screen and (max-width: 800px) {
  /* Force table to not be like tables anymore */
  #no-more-tables table,
  #no-more-tables thead,
  #no-more-tables tbody,
  #no-more-tables th,
  #no-more-tables td,
  #no-more-tables tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  #no-more-tables thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  // #no-more-tables tr { border: 1px solid #ccc; }

  #no-more-tables td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
    white-space: normal;
    text-align: left;
  }

  /*
Label the data
*/
  #no-more-tables td:before {
    content: attr(data-title);
  }
}

.w-100 {
  width: 100%;
}

#no-more-tables {
  th {
    padding: 20px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    background: #f5f5f8 !important;
    border: none !important;
    margin: 0px !important;
    border: none !important;
  }
  td {
    // padding: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
    border-bottom: 1px solid #f5f5f8;
  }
}

@media (max-width: 800px) {
  #no-more-tables {
    th {
      padding: 20px !important;
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      background: #f5f5f8 !important;
      border: none !important;
      margin: 0px !important;
      border: none !important;
    }
    td {
      // padding: 10px;
      padding-bottom: 5px;
      padding-top: 5px;
      border-bottom: 1px solid #f5f5f8;
      min-height: 22px;
    }
    tbody {
      tr {
        margin-bottom: 10px;
        //border-top: 4px solid #ddd;
        padding: 20px;
        padding-bottom: 0px;
        padding-top: 0px;

        td {
          padding-bottom: 5px;
          padding-top: 5px;
        }
        .mobile-text {
          margin: 0px;
          font-style: normal;
          font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif;
          font-size: 13px;
          line-height: 22px;
          color: rgb(18, 0, 59);
          font-weight: 600;
        }
      }

      tr:nth-child(1) {
        border: none !important;
      }
    }
    td:before {
      position: absolute;
      top: 4px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      text-align: left;
      font-weight: 600;
      color: hsl(240deg 18% 85%);
    }
    td:nth-last-child(1) {
      border-bottom: 1px solid #4110b7;
    }
  }
}
@media (min-width: 801px) {
  .Document {
    #no-more-tables {
      table {
        border-collapse: collapse;
        th {
          padding-top: 5px !important;
          padding-bottom: 5px !important;
          background: #f5f5f8 !important;
          border: none !important;
          margin: 0px !important;
          border: none !important;
          text-align: left;
          padding-left: 10px !important;
          padding-right: 10px !important;
        }
        td {
          padding: 10px;
          padding-bottom: 5px;
          padding-top: 5px;
          border-bottom: 1px solid #f5f5f8;
        }
        tbody {
          td:nth-child(4) {
            width: 300px;
          }
          td:nth-child(2) {
            width: 150px;
          }
          td:nth-child(3) {
            width: 150px;
          }
          tr:hover {
            box-shadow: rgb(18 0 59 / 30%) 0px 1px 3px, rgb(18 0 59 / 4%) 0px -1px 8px !important;
            background-color: #dddddd7d;
            cursor: pointer !important;
          }
        }
      }
    }
  }
}

.action {
  .download {
    margin: auto;
    svg {
      height: 20px !important;
    }
  }
}

// ----


.impDoc {
  list-style: none;
  margin: 0px;
  padding: 0px;
  width: 100%;
  display: block !important;
  li {
    border-bottom: 1px solid rgb(227, 225, 232) ;

    .eUedHw {
      transition: all 150ms linear 0s ;
      text-align: left ;
      width: 100% ;

      .bcxYtT {
        margin: 0px ;
        padding: 0px ;
        background: none ;
        border: none ;
        cursor: pointer ;
        width: 100% ;

        .fNPVNt {
          box-sizing: border-box ;
          padding-top: 12px ;
          padding-bottom: 12px ;
          display: flex ;
          -webkit-box-pack: justify ;
          justify-content: space-between ;

          .cnKnPt {
            margin: 0px ;
            font-style: normal ;
            font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif ;
            font-size: 16px ;
            line-height: 26px ;
            color: rgb(18, 0, 59) ;
            font-weight: normal ;
            text-align: left ;
          }

          .eLMudl {
            padding-left: 16px ;
            // margin: auto;
            display: flex;
            align-self: center;
            .ItfWz {
              color: rgb(111, 112, 140) ;
              .icon {
                vertical-align: middle ;
                width: 24px ;
                height: 24px ;
                stroke-width: 2 ; 
              }
            }
          }
        }
      }

      .bcxYtT:hover{
        .cnKnPt , .icon{
          color: rgb(96, 12, 232);
          text-decoration: none;
        }
      }
    }
  }

  li:nth-last-child(1){
    border-bottom: none !important;
  }
}


.document-View{
  margin-left: 0% !important;
  margin-right: 0% !important;
  margin-top: 0px !important;
}


@media (max-width: 959px) {
  .document-View {
    margin-left: 0% !important;
    margin-right: 0% !important;
    .css-sp7t83 {
      display: block !important;
      .css-iz72e7 {
        display: block !important;
        width: 100% !important;
        margin-bottom: 10px !important;
      }
      .css-1gxohh2 {
        display: block !important;
        width: 100% !important;
      }
    }
    .p-15 {
      padding: 0 1% !important;
    }
  }
}
@media (min-width: 960px) {
  .document-View {
    .p-15 {
      padding: 0 25% 0 0 !important;
    }
  }
}
.document-View {
  // div {
  //   display: block;
  // }
  // margin-left: 27%;
  // margin-right: 27%;
  body {
    --webkit-font-smoothing: antialiased;
    background-color: rgb(245, 245, 248);
    // font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Helvetica Neue",
    //   Helvetica, sans-serif;
  }
  .css-sp7t83 {
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
    margin-top: 24px;
  }
  .css-iz72e7 {
    display: flex;
    box-sizing: border-box;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: fit-content;
    font-weight: 600;
    border-width: 3px;
    border-style: solid;
    border-color: transparent;
    cursor: pointer;
    line-height: 22px;
    transition-duration: 150ms;
    height: 32px;
    min-width: 96px;
    font-size: 14px;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 16px;
    color: rgb(96, 12, 232);
    background-color: rgb(245, 245, 248);
    margin-right: 12px;
  }
  .css-p {
    margin: 0px;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: black;
  }
  .css-text {
    color: black;
  }
  .css-1gxohh2 {
    display: flex;
    box-sizing: border-box;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: fit-content;
    font-weight: 600;
    border-width: 3px;
    border-style: solid;
    cursor: pointer;
    line-height: 22px;
    transition-duration: 150ms;
    height: 32px;
    min-width: 96px;
    font-size: 14px;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 16px;
    color: rgb(96, 12, 232);
    background-color: transparent;
    border-color: rgb(96, 12, 232);
  }
  .css-1qm12h {
    margin: 0px;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: black;
  }
  .css-ltsw9u {
    display: flex;
    flex-flow: row wrap;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 1152px;
    padding-left: 12px;
    padding-right: 12px;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    align-items: stretch;
  }
  .css-naxvie {
    box-sizing: border-box;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    display: block;
  }
  .css-zaghsn {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-top: 24px;
    // margin-bottom: 24px;
  }
  h1 {
    margin: 0px;
    font-size: 28px;
    font-weight: 700;
    line-height: 42px;
  }
  .css-18ee882 {
    display: flex;
    box-sizing: border-box;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: fit-content;
    font-weight: 600;
    border-width: 3px;
    border-style: solid;
    border-color: transparent;
    cursor: pointer;
    line-height: 22px;
    transition-duration: 150ms;
    height: 32px;
    min-width: 96px;
    font-size: 14px;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 16px;
    color: rgb(255, 255, 255);
    background-color: rgb(96, 12, 232);
  }

  .css-1qbatn3 {
    box-sizing: border-box;
    padding-left: 12px;
    padding-right: 12px;
    width: 50%;
    display: block;
  }

  .css-1ulh703 {
    margin-bottom: 48px;
  }

  .css-18akwle {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 12px;
  }
  .css-vy2pbh {
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    box-shadow: rgb(18 0 59 / 12%) 0px 2px 6px, rgb(18 0 59 / 4%) 0px -1px 5px;
    border-radius: 16px;
  }
  .css-12u19mu {
    position: absolute;
    top: 12px;
    right: 12px;
  }
  .css-3or2ho {
    height: 24px;
    max-width: max-content;
    display: flex;
    padding: 0px 12px;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    border-radius: 32px;
    color: rgb(0, 170, 160);
    background-color: rgb(201, 254, 239);
  }
  .css-xqrdoy {
    padding: 32px;
  }
  .css-1qm1lh {
    margin-bottom: 16px;
  }
  .css-fqk8rb {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    padding-bottom: 4px;
    color: rgb(111, 112, 140);
  }
  .css-fqk8rb {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    padding-bottom: 4px;
    color: rgb(111, 112, 140);
  }
  .css-1crpfoq {
    margin: 0px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    color: rgb(0, 0, 0);
  }
  .css-1ulh703 {
    margin-bottom: 48px;
  }
  .css-18akwle {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 12px;
  }
  .css-1rpx5uk {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: rgb(96, 12, 232);
  }
  .css-vy2pbh {
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    box-shadow: rgb(18 0 59 / 12%) 0px 2px 6px, rgb(18 0 59 / 4%) 0px -1px 5px;
    border-radius: 16px;
  }
  .css-xqrdoy {
    padding: 32px;
  }
  .css-1qm1lh {
    margin-bottom: 16px;
  }
  .css-fqk8rb {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    padding-bottom: 4px;
    color: rgb(111, 112, 140);
  }
  .css-1qm1lh {
    margin-bottom: 16px;
  }
  .css-fqk8rb {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    padding-bottom: 4px;
    color: rgb(111, 112, 140);
  }
  .css-1crpfoq {
    margin: 0px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    color: rgb(0, 0, 0);
  }
  .css-fqk8rb {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    padding-bottom: 4px;
    color: rgb(111, 112, 140);
  }
  .css-1crpfoq {
    margin: 0px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    color: rgb(0, 0, 0);
  }
  .css-1ulh703 {
    margin-bottom: 48px;
  }
  .css-18akwle {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 12px;
  }
  .css-1rpx5uk {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: rgb(96, 12, 232);
  }

  .css-vy2pbh {
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    box-shadow: rgb(18 0 59 / 12%) 0px 2px 6px, rgb(18 0 59 / 4%) 0px -1px 5px;
    border-radius: 16px;
  }

  .css-xqrdoy {
    padding: 32px;
  }

  .css-1b7waib {
    margin: 0px 0px 16px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: rgb(111, 112, 140);
    text-transform: uppercase;
  }
  p {
    margin: 0px;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
  }
  p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  .css-1081t4c {
    text-decoration: underline;
  }
  .css-1ut6rcl {
    margin: 0px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: black;
  }
  .css-1ulh703 {
    margin-bottom: 48px;
  }
  .css-18akwle {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 12px;
  }
  .css-vy2pbh {
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    box-shadow: rgb(18 0 59 / 12%) 0px 2px 6px, rgb(18 0 59 / 4%) 0px -1px 5px;
    border-radius: 16px;
  }
  .css-xqrdoy {
    padding: 32px;
  }
  .css-1qbatn3 {
    box-sizing: border-box;
    padding-left: 12px;
    padding-right: 12px;
    width: 50%;
    display: block;
  }
  .css-1ulh703 {
    margin-bottom: 48px;
  }
  .css-18akwle {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 12px;
  }
  .css-phtnxg {
    transition-duration: 150ms;
    color: rgb(96, 12, 232);
    font-weight: 600;
    font-size: 12px;
  }
  .css-vy2pbh {
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    box-shadow: rgb(18 0 59 / 12%) 0px 2px 6px, rgb(18 0 59 / 4%) 0px -1px 5px;
    border-radius: 16px;
  }
  .css-xqrdoy {
    padding: 32px;
  }

  .css-1wj0762 {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-end;
  }
  .css-fqk8rb {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    padding-bottom: 4px;
    color: rgb(111, 112, 140);
  }
  .css-1crpfoq {
    margin: 0px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    color: rgb(0, 0, 0);
  }
  .css-1rpx5uk {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: rgb(96, 12, 232);
  }
  .css-1ulh703 {
    margin-bottom: 48px;
  }
  .css-18akwle {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 12px;
  }
  .css-phtnxg {
    transition-duration: 150ms;
    color: rgb(96, 12, 232);
    font-weight: 600;
    font-size: 12px;
  }
  .css-vy2pbh {
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    box-shadow: rgb(18 0 59 / 12%) 0px 2px 6px, rgb(18 0 59 / 4%) 0px -1px 5px;
    border-radius: 16px;
  }
  .css-12u19mu {
    position: absolute;
    top: 12px;
    right: 12px;
  }
  .css-3or2ho {
    height: 24px;
    max-width: max-content;
    display: flex;
    padding: 0px 12px;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    border-radius: 32px;
    color: rgb(0, 170, 160);
    background-color: rgb(201, 254, 239);
  }
  .css-xqrdoy {
    padding: 32px;
  }
  .css-1oimov7 {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 12px;
    position: relative;
  }
  .css-1vg6q84 {
    font-weight: 700;
    color: black;
  }
  .css-1wj0762 {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-end;
  }
  .css-fqk8rb {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    padding-bottom: 4px;
    color: rgb(111, 112, 140);
  }
  .css-1crpfoq {
    margin: 0px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    color: rgb(0, 0, 0);
  }
  .css-fqk8rb {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    padding-bottom: 4px;
    color: rgb(111, 112, 140);
  }
  .css-1crpfoq {
    margin: 0px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    color: rgb(0, 0, 0);
  }
  .css-s2uf1z {
    text-align: right;
  }
  .css-fqk8rb {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    padding-bottom: 4px;
    color: rgb(111, 112, 140);
  }
  .css-1crpfoq {
    margin: 0px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    color: rgb(0, 0, 0);
  }
  .css-1ulh703 {
    margin-bottom: 48px;
  }
  .css-18akwle {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 12px;
  }
  .css-phtnxg {
    transition-duration: 150ms;
    color: rgb(96, 12, 232);
    font-weight: 600;
    font-size: 12px;
  }
  .css-vy2pbh {
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    box-shadow: rgb(18 0 59 / 12%) 0px 2px 6px, rgb(18 0 59 / 4%) 0px -1px 5px;
    border-radius: 16px;
  }
  .css-1gvwazu {
    border-left: 4px solid transparent;
    overflow: hidden;
    padding: 24px 32px;
    transition-duration: 150ms;
  }
  .css-9jay18 {
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .css-165znzt {
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    flex-grow: 1;
  }
  .css-1jqvzpx {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: rgb(111, 112, 140);
    min-width: 90px;
  }
  .css-1vseuxq {
    margin: 0px 16px 0px 0px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: rgb(0, 0, 0);
    -webkit-box-flex: 1;
    flex-grow: 1;
  }
  .css-18wwfoy {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: rgb(111, 112, 140);
    min-width: 100px;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
  }
  .css-1l0z8uk {
    flex-direction: row;
  }
  .css-nb6ul0 {
    margin-right: 4px;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
  }
  .css-1qvwzoe {
    border-color: rgb(111, 112, 140);
    border-width: 1px;
    border-radius: 7px;
    height: 7px;
    width: 7px;
    background-color: rgb(111, 112, 140);
  }
  .css-1e8abgn {
    width: 4px;
    height: 1px;
    background-color: rgb(111, 112, 140);
  }
  .css-1s6qyqw {
    border-color: rgb(111, 112, 140);
    border-width: 1px;
    border-radius: 7px;
    height: 7px;
    width: 7px;
    background-color: rgb(227, 225, 232);
  }
  .css-1s9eamm {
    width: 4px;
    height: 1px;
    background-color: rgb(227, 225, 232);
  }
  .css-1s6qyqw {
    border-color: rgb(111, 112, 140);
    border-width: 1px;
    border-radius: 7px;
    height: 7px;
    width: 7px;
    background-color: rgb(227, 225, 232);
  }
  .css-18wwfoy span {
    padding-left: 4px;
  }
  .css-1kecnp1 {
    margin: 0px 16px 0px 0px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: rgb(0, 0, 0);
    min-width: 80px;
    text-align: right;
    align-self: center;
  }
  .css-oj9lgu {
    max-height: 0px;
    overflow: hidden;
    transition-duration: 150ms;
  }
  .css-1bep9n9 {
    border-top: 1px solid rgb(227, 225, 232);
    margin-left: 90px;
    margin-top: 24px;
    padding-top: 24px;
  }
  .css-1gvwazu:not(:first-child) {
    border-top: 1px solid rgb(239, 238, 242);
  }
  .css-1gvwazu {
    border-left: 4px solid transparent;
    overflow: hidden;
    padding: 24px 32px;
    transition-duration: 150ms;
  }
  .css-9jay18 {
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .css-165znzt {
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    flex-grow: 1;
  }
  .css-1jqvzpx {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: rgb(111, 112, 140);
    min-width: 90px;
  }
  .css-1vseuxq {
    margin: 0px 16px 0px 0px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: rgb(0, 0, 0);
    -webkit-box-flex: 1;
    flex-grow: 1;
  }
  .css-18wwfoy {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: rgb(111, 112, 140);
    min-width: 100px;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
  }
  .css-1l0z8uk {
    flex-direction: row;
  }
  .css-nb6ul0 {
    margin-right: 4px;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
  }
  .css-19jkesh {
    border-color: rgb(111, 112, 140);
    border-width: 1px;
    border-radius: 7px;
    height: 7px;
    width: 7px;
    background-color: rgb(8, 210, 178);
  }
  .css-1rxuejg {
    width: 4px;
    height: 1px;
    background-color: rgb(8, 210, 178);
  }
  .css-19jkesh {
    border-color: rgb(111, 112, 140);
    border-width: 1px;
    border-radius: 7px;
    height: 7px;
    width: 7px;
    background-color: rgb(8, 210, 178);
  }
  .css-1rxuejg {
    width: 4px;
    height: 1px;
    background-color: rgb(8, 210, 178);
  }
  .css-19jkesh {
    border-color: rgb(111, 112, 140);
    border-width: 1px;
    border-radius: 7px;
    height: 7px;
    width: 7px;
    background-color: rgb(8, 210, 178);
  }
  .css-1kecnp1 {
    margin: 0px 16px 0px 0px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: rgb(0, 0, 0);
    min-width: 80px;
    text-align: right;
    align-self: center;
  }
  .css-oj9lgu {
    max-height: 0px;
    overflow: hidden;
    transition-duration: 150ms;
  }
  .css-1bep9n9 {
    border-top: 1px solid rgb(227, 225, 232);
    margin-left: 90px;
    margin-top: 24px;
    padding-top: 24px;
  }
  .css-1bep9n9 {
    border-top: 1px solid rgb(227, 225, 232);
    margin-left: 90px;
    margin-top: 24px;
    padding-top: 24px;
  }
  .css-1u6w44c {
    padding: 32px;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    height: 120px;
  }
  .css-1vj6hdk {
    margin-top: 32px;
    width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    display: none;
    color: rgb(111, 112, 140);
    font-size: 14px;
  }
  .css-1iuj5ih {
    padding-left: 8px;
  }

  .MuiTab-textColorInherit {
    background-color: rgb(227, 225, 232) !important;
    color: rgb(96, 12, 232) !important;
    border: none !important;
    border-radius: 10px !important;
    margin-bottom: 10px;
    min-width: 200px !important;
  }
  .PrivateTabIndicator-colorSecondary-35 {
    background-color: transparent !important;
  }
}

.title {
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
  margin-bottom: 10px;
  font-size: 20px;
}



.eFrFNd {
  margin: 0px;
  font-weight: normal;
  font-style: normal;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 16px;
  line-height: 26px;
  color: rgb(18, 0, 59);
  .css-1hgfhw8 {
    transition-duration: 150ms;
    color: rgb(96, 12, 232);
    font-weight: 600;
    text-decoration: none;
  }
}
.foxWyQ {
  padding: 32px !important;
  .cJAFac {
    margin-bottom: 24px;
    .gkRBAV {
      display: flex;
      flex-direction: column;
      .cdDuvV {
        margin-bottom: 16px;
        .eFrFNd {
          margin: 0px;
          font-weight: normal;
          font-style: normal;
          font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif;
          font-size: 16px;
          line-height: 26px;
          color: rgb(18, 0, 59);
        }
      }
      .jPDzqR {
        margin: 0px;
        font-style: normal;
        font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif;
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        color: rgb(18, 0, 59);
      }
      .hfzgIf {
        box-sizing: border-box;
        background-color: rgb(245, 245, 248);
        border-radius: 16px;
        padding: 24px;
        padding-bottom: 9px;
        .bouexZ {
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          -webkit-box-pack: justify;
          justify-content: space-between;
        }
      }
    }
  }
}
.hbVuhF {
  vertical-align: middle;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.XqjMf {
  vertical-align: middle;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
