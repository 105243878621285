@media (max-width: 960px) {
  //mobile view
  .addrequest {
    margin-left: 10% !important;
    margin-right: 10% !important;
    display: flex;
    justify-content: center;
    .category {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .NQ-web {
        margin-right: 15px;
        .NQ-card-body {
          padding: 10px 0px !important;
        }
      }
      .NQ-web:nth-child(1) {
        // margin-left: 10px;
      }
      .cartory-border {
        border: 2px solid #600ce8;
        border-radius: 10px;
      }
    }
    .makeStyles-grid-40 {
      padding: 0 !important;
    }
    .MuiTypography-body1 {
      .MuiGrid-container {
        display: block;
      }
    }
    .mobileview {
      //  margin: 0 3% !important;
    }
    .css-q4good {
      box-sizing: border-box;

      display: block;
      .css-1tu684v {
        text-align: center;
        margin-bottom: 24px;
        margin-top: 15px;
        font-size: 18px !important;
      }
      h1 {
        margin: 0;
        font-size: 24px;
        font-weight: 600;
        line-height: 42px;
      }
    }
    .css-1yjvs5a {
      margin: 0px !important;
    }

    .NQ-Input-Text {
      .css-79elbk {
        .css-npjedo {
          // margin: 0 40px !important;
          width: auto !important;
        }
      }
    }
    .cAmEDQ {
      width: 100vw;
    }
    .MuiAccordion-root {
      // margin: 5px 60px !important;
      width: auto !important;
    }
    .css-1ucoqeb {
      background-color: #efeef2;
      margin-bottom: 5%;
      border-radius: 8px;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 24px;
      .sc-hhIhEF {
        text-align: center;
      }
      mat-label {
        text-align: center;
      }
    }
    .css-dvxtzn {
      display: flex;
      flex-direction: column;
      -webkit-box-align: center;
      align-items: center;
      margin-top: 25px;
      .css-jhd49u {
        display: flex;
        box-sizing: border-box;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        width: fit-content;
        font-weight: 600;
        border-width: 3px;
        border-style: solid;
        border-color: transparent;
        cursor: pointer;
        line-height: 22px;
        transition-duration: 150ms;
        height: 56px;
        min-width: 220px;
        font-size: 16px;
        padding-left: 32px;
        padding-right: 32px;
        border-radius: 32px;
        color: rgb(255, 255, 255);
        background-color: rgb(96, 12, 232);
        margin-bottom: 12px;
      }
      .css-jhd49u:focus {
        border-color: rgb(219, 175, 254);
        outline: none;
      }
      .css-jhd49u:disabled {
        cursor: not-allowed;
      }
      .css-jhd49u:focus,
      .css-jhd49u:hover,
      .css-jhd49u:active {
        background-color: rgb(65, 16, 183);
      }
      .css-jhd49u:disabled {
        color: rgba(255, 255, 255, 0.4);
        background-color: rgba(96, 12, 232, 0.4);
      }
      .css-1gld5x9 {
        display: flex;
        box-sizing: border-box;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        width: fit-content;
        font-weight: 600;
        border-width: 3px;
        border-style: solid;
        border-color: transparent;
        cursor: pointer;
        line-height: 22px;
        transition-duration: 150ms;
        height: 56px;
        min-width: 220px;
        font-size: 16px;
        padding-left: 32px;
        padding-right: 32px;
        border-radius: 32px;
        color: rgb(96, 12, 232);
        background-color: rgb(245, 245, 248);
      }
      .css-1gld5x9:focus {
        border-color: rgb(219, 175, 254);
        outline: none;
      }
      .css-1gld5x9:disabled {
        cursor: not-allowed;
      }
      .css-1gld5x9:focus,
      .css-1gld5x9:hover,
      .css-1gld5x9:active {
        background-color: rgb(96, 12, 232);
        color: rgb(255, 255, 255);
      }
      .css-1gld5x9:disabled {
        color: rgba(96, 12, 232, 0.4);
      }
    }
    .MuiAccordionDetails-root {
      .NQ-Input-Text {
        .css-79elbk {
          .css-npjedo {
            margin: 0px !important;
            width: auto !important;
          }
        }
      }
    }
    .css-label {
      color: #000;
    }

    .next {
      // margin: 0 3% !important;
      .sc-oefIU {
        // display: grid;
        // justify-content: center;
      }
      .text-center {
        text-align: center;
      }
      .sc-hhIhEF {
        .css-1ucoqeb {
          // margin: 2% 15% !important;
        }
      }
      .MuiSlider-colorPrimary {
        width: 80% !important;
        margin: 0 10% !important;
      }
    }
  }
  .makeStyles-grid-36 {
    padding: 0 !important;
  }
}
@media (min-width: 960px) {
  //web view
  .addrequest > div {
    align-self: center;
  }
  .addrequest {
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 100px);
    .category {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .NQ-web {
        margin-right: 15px;
        .NQ-card-body {
          padding: 10px 0px !important;
        }
      }
      .NQ-web:nth-child(1) {
      }
      .cartory-border {
        border: 2px solid #600ce8;
        border-radius: 10px;
      }
    }
    .makeStyles-grid-40 {
      padding: 0 !important;
    }
    .MuiTypography-body1 {
      .MuiGrid-container {
        display: block;
      }
    }
    .css-q4good {
      box-sizing: border-box;
      display: block;
      width: 23vw;
      .css-1tu684v {
        text-align: center;
      }
      h1 {
        margin: 0;
        font-size: 24px;
        font-weight: 600;
        line-height: 42px;
      }
    }
    .css-1yjvs5a {
      margin-bottom: 30px;
    }
    .note {
      text-align: center;
      margin-bottom: 10px;
    }
    .NQ-Input-Text {
      .css-79elbk {
        .css-npjedo {
          // margin: 0 50px !important;
          width: auto !important;
        }
      }
    }
    .MuiAccordion-root {
      // margin: 5px 50px !important;
    }
    .css-1ucoqeb {
      background-color: #efeef2;
      margin-bottom: 5%;
      border-radius: 8px;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 24px;
      .sc-hhIhEF {
        text-align: center;
      }
      mat-label {
        text-align: center;
      }
    }
    .css-dvxtzn {
      display: flex;
      flex-direction: column;
      -webkit-box-align: center;
      align-items: center;
      margin-top: 25px;
      .css-jhd49u {
        display: flex;
        box-sizing: border-box;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        width: fit-content;
        font-weight: 600;
        border-width: 3px;
        border-style: solid;
        border-color: transparent;
        cursor: pointer;
        line-height: 22px;
        transition-duration: 150ms;
        height: 56px;
        min-width: 220px;
        font-size: 16px;
        padding-left: 32px;
        padding-right: 32px;
        border-radius: 32px;
        color: rgb(255, 255, 255);
        background-color: rgb(96, 12, 232);
        margin-bottom: 12px;
      }
      .css-jhd49u:focus {
        border-color: rgb(219, 175, 254);
        outline: none;
      }
      .css-jhd49u:disabled {
        cursor: not-allowed;
      }
      .css-jhd49u:focus,
      .css-jhd49u:hover,
      .css-jhd49u:active {
        background-color: rgb(65, 16, 183);
      }
      .css-jhd49u:disabled {
        color: rgba(255, 255, 255, 0.4);
        background-color: rgba(96, 12, 232, 0.4);
      }
      .css-1gld5x9 {
        display: flex;
        box-sizing: border-box;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        width: fit-content;
        font-weight: 600;
        border-width: 3px;
        border-style: solid;
        border-color: transparent;
        cursor: pointer;
        line-height: 22px;
        transition-duration: 150ms;
        height: 56px;
        min-width: 220px;
        font-size: 16px;
        padding-left: 32px;
        padding-right: 32px;
        border-radius: 32px;
        color: rgb(96, 12, 232);
        background-color: rgb(245, 245, 248);
      }
      .css-1gld5x9:focus {
        border-color: rgb(219, 175, 254);
        outline: none;
      }
      .css-1gld5x9:disabled {
        cursor: not-allowed;
      }
      .css-1gld5x9:focus,
      .css-1gld5x9:hover,
      .css-1gld5x9:active {
        background-color: rgb(96, 12, 232);
        color: rgb(255, 255, 255);
      }
      .css-1gld5x9:disabled {
        color: rgba(96, 12, 232, 0.4);
      }
    }
    .MuiAccordionDetails-root {
      .NQ-Input-Text {
        .css-79elbk {
          .css-npjedo {
            margin: 0px !important;
            width: auto !important;
          }
        }
      }
    }
    .css-label {
      color: #000;
    }

    .next {
      .text-center {
        text-align: center;
      }
      .sc-hhIhEF {
        .sc-oefIU {
          margin-bottom: 10px;
          // padding: 0 45px !important;
        }
        .css-1ucoqeb {
          // margin: 2% 25% !important;
        }
      }
      .MuiSlider-colorPrimary {
        width: 80% !important;
        margin: 0 10% !important;
      }
    }

    .Client-Card {
      border: 1px solid #ddd;
      background-color: #fff;
      margin-right: 0;
      min-height: 60px;
      box-shadow: rgb(18 0 59 / 12%) 0px 2px 6px, rgb(18 0 59 / 4%) 0px -1px 5px;
      margin-bottom: 10px;
      border-radius: 16px;
      .Client-Card-header {
        height: 20px;
        background: #600ce8;
        border-top-left-radius: 14px;
        border-top-right-radius: 14px;
        label {
          color: #fff;
          padding-left: 11px;
          font-size: 13px;
        }
      }
      .Client-Card-body {
        border: 2px solid #600ce8;
        height: 41px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        padding: 0 8px;
        label {
          font-size: 13px;
          span {
            color: #600ce8;
            font-weight: 700;
          }
        }
        .col-2 {
          display: flex;
          justify-content: space-between;
          margin-top: -8px;
          label {
            font-size: 13px;
            span {
              color: #600ce8;
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  //5/20/2021

  .nextstep {
    margin: 0px !important;
  }
  //5/20/2021
}

.Mui-selected {
  border: 2px solid #4110b7 !important;
  background-color: #fff !important;
}

.basic-single {
  // margin: 5% 0 0px 0px;
}

.dzu-dropzone {
  border: 1px solid #600ce8 !important;
  min-height: 40px !important;
  overflow: hidden !important;
  .dzu-inputLabel {
    font-size: 15px !important;
    color: #600ce8 !important;
  }
  .dzu-inputLabelWithFiles {
    color: #600ce8 !important;
  }
}
.dzu-submitButtonContainer {
  background-color: #600ce8 !important;
  // display: none !important;
}

.dzu-previewContainer {
  padding: 0 3% !important;
  min-height: 43px !important;
  z-index: 0 !important;
  img {
    width: 80px !important;
  }
}
.dzu-previewContainer {
  z-index: 0 !important;
  .dzu-previewFileName {
    white-space: nowrap;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.cat-title {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  font-size: 20px;
}
.NQ-card.goal-card:hover {
  border: 2px solid #600ce8 !important;
}

/*! CSS Used from: Embedded */
p {
  margin: 0px;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}
.css-olq2dz {
  margin: 0px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: rgb(111, 112, 140);
  padding-bottom: 0px;
}
.css-1crpfoq {
  margin: 0px;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  color: rgb(0, 0, 0);
}
@media (max-width: 560px) {
  .css-1crpfoq {
    overflow-wrap: break-word;
  }
}
/*! CSS Used from: Embedded */
.foxWyQ {
  box-sizing: border-box;
  background-color: rgb(255, 255, 255);
  border-radius: 16px;
  box-shadow: rgba(18, 0, 59, 0.12) 0px 2px 6px, rgba(18, 0, 59, 0.04) 0px -1px 5px;
}
@media (min-width: 0em) {
  .foxWyQ {
    padding: 24px;
  }
}
@media (min-width: 34.8125em) {
  .foxWyQ {
    padding: 32px;
  }
}
.gkRBAV {
  display: flex;
  flex-direction: column;
}
.cdDuvV {
  margin-bottom: 16px;
}
.cdDuvV:last-of-type {
  margin-bottom: 0px;
}
.bOCWrd {
  margin-bottom: 12px;
}
.bOCWrd:last-of-type {
  margin-bottom: 0px;
}

.ABCsvgIcon {
  .svg {
    height: 20px !important;
  }
  .SVGCloseIcon {
    color: red;
    .svg {
      height: 20px !important;
    }
    .MuiSvgIcon-root {
      height: 20px !important;
    }
  }
}

.dzu-previewFileName {
  width: auto !important;
}

// .dzu-previewStatusContainer {
//   display: none !important;
// }


.css-xqrdoy {
  padding: 32px;
}

.css-1qm1lh {
  margin-bottom: 16px;
}

.css-vy2pbh {
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: rgb(18 0 59 / 12%) 0px 2px 6px, rgb(18 0 59 / 4%) 0px -1px 5px;
  border-radius: 16px;
}
.css-fqk8rb {
  margin: 0px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  padding-bottom: 4px;
  color: rgb(111, 112, 140);
}
.css-1crpfoq {
  margin: 0px;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  color: rgb(0, 0, 0);
}



.addrequest{
  .css-1ulh703 {
    margin-bottom: 10px;
    margin-left: 15px;
      margin-right: 15px;
  }
}