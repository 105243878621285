.content {
  max-width: 500px;
  margin: auto;
  background: white;
  padding: 10px;
}
.goaltext {
  font-size: 20px !important;
  font-weight: 700 !important;
}

@media (max-width: 450px) {
  .potcard {
    .title {
      font-size: 20px;
      margin-bottom: 10px;
    }
    .NQ-web {
      .NQ-card {
        box-sizing: border-box;
        width: 100%;
        border-radius: 24px;
        box-shadow: rgb(18 0 59 / 12%) 1px 4px 24px, rgb(18 0 59 / 8%) 0px 4px 8px;
        background-color: rgb(255, 255, 255);
        position: relative;
        padding: 32px;
        height: 100%;

        .NQ-card-body {
          display: block !important;
          .NQ-left {
            width: 100% !important;
            justify-content: space-around !important;
          }
          .NQ-right {
            width: 100% !important;
            justify-content: center !important;
            margin-left: -5px !important;
          }
        }
      }
    }
  }
}

@media (min-width: 960px) {
  .potcard{
    .remove-padding{
      .makeStyles-grid-35{
        padding: 0px !important;
      }
    }
  }
  .potcard {
    .title {
      font-size: 20px;
      margin-bottom: 20px;
    }

    .title {
      font-size: 20px;
      margin-bottom: 10px;
    }

    .mb-10 {
      margin-bottom: 10px;
    }

    .action-area {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      margin-bottom: 10px;
      .title {
        font-size: 20px;
      }
      .action {
        display: flex;
      }
    }

    .NQ-web .NQ-card .NQ-card-body {
      display: block !important;
      .NQ-left {
        justify-content: end !important;
        .NQ-col-1 {
          display: block;
          align-self: center;
        }
        .NQ-col-2 {
          display: flex;
          align-items: center;
          margin-left: 10px;
          .text-wrap {
          }
        }
        .goaltext {
          font-size: 20px;
          font-weight: 700;
        }
        .goalcategory {
          font-size: 10px;
        }
      }
    }
    .NQ-web .NQ-card .NQ-card-body .NQ-right {
      justify-content: space-between !important;
      .NQ-col-1 {
        align-self: center;
      }
    }
  }
}

button {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
button {
  overflow: visible;
}
button {
  text-transform: none;
}
button {
  -webkit-appearance: button;
}
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring {
  outline: 1px dotted ButtonText;
}
/*! CSS Used from: Embedded */
button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  margin: 0px;
  outline: none;
  overflow: visible;
  padding: 0px;
  text-transform: none;
}

/*! CSS Used from: https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css */
a {
  background-color: transparent;
}
button {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
button {
  overflow: visible;
}
button {
  text-transform: none;
}
button {
  -webkit-appearance: button;
}
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring {
  outline: 1px dotted ButtonText;
}
/*! CSS Used from: Embedded */
p {
  margin: 0px;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}
button {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  margin: 0px;
  outline: none;
  overflow: visible;
  padding: 0px;
  text-transform: none;
}
a {
  color: rgb(96, 12, 232);
  cursor: pointer;
  text-decoration: none;
  transition-duration: 150ms;
}
a:hover {
  text-decoration: underline;
}
.css-olq2dz {
  margin: 0px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: rgb(111, 112, 140);
  padding-bottom: 0px;
}
.css-1crpfoq {
  margin: 0px;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  color: rgb(0, 0, 0);
}
@media (max-width: 560px) {
  .css-1crpfoq {
    overflow-wrap: break-word;
  }
}
.css-1gsak55 {
  transition-duration: 150ms;
  color: rgb(96, 12, 232);
  font-weight: 600;
  text-decoration: none;
  font-size: inherit;
}
.css-1gsak55:hover {
  color: rgb(47, 10, 135);
}
.css-gg4vpm {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.css-182w5ex {
  margin: 0px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: rgb(111, 112, 140);
}
.css-x27n60 {
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  cursor: pointer;
  border-radius: 32px;
  padding: 2px 12px;
  background-color: transparent;
  color: rgb(111, 112, 140);
}
/*! CSS Used from: Embedded */
.kDutJv {
  box-sizing: border-box;
}
@media (min-width: 0em) {
  .kDutJv {
    height: auto;
  }
}
@media (min-width: 50.5625em) {
  .kDutJv {
    height: 350px;
  }
}
.heKDCO {
  box-sizing: border-box;
  width: 100%;
  border-radius: 24px;
  box-shadow: rgba(18, 0, 59, 0.12) 1px 4px 24px, rgba(18, 0, 59, 0.08) 0px 4px 8px;
  background-color: rgb(255, 255, 255);
  position: relative;
  padding: 32px;
  height: 100%;
}
.kHCljX {
  box-sizing: border-box;
  margin-top: 16px;
  display: flex;
}
.bezQXd {
  box-sizing: border-box;
  position: relative;
  height: 100px;
  margin-bottom: 24px;
}
.epYKIo {
  box-sizing: border-box;
  padding-top: 16px;
}
.bLfvka {
  padding-top: 1px;
}
.bLfvka::before {
  display: block;
  content: "";
  margin-top: -1px;
}
.eZaNeK {
  display: flex;
  flex-flow: row wrap;
  margin-left: -24px;
}
.jzvMBZ {
  box-sizing: border-box;
  padding-left: 24px;
}
.kOebjS {
  margin: 0px;
  font-style: normal;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: rgb(18, 0, 59);
  font-weight: 600;
}
.ebARNv {
  margin: 0px;
  font-style: normal;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif;
  font-weight: 700;
  color: rgb(18, 0, 59);
}
@media (min-width: 0em) {
  .ebARNv {
    font-size: 28px;
  }
}
@media (min-width: 50.5625em) {
  .ebARNv {
    font-size: 32px;
  }
}
@media (min-width: 0em) {
  .ebARNv {
    line-height: 42px;
  }
}
@media (min-width: 50.5625em) {
  .ebARNv {
    line-height: 46px;
  }
}
/*! CSS Used from: Embedded */
.chartjs-render-monitor {
  animation: chartjs-render-animation 0.001s;
}
.chartjs-size-monitor,
.chartjs-size-monitor-expand,
.chartjs-size-monitor-shrink {
  position: absolute;
  direction: ltr;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
  visibility: hidden;
  z-index: -1;
}
.chartjs-size-monitor-expand > div {
  position: absolute;
  width: 1000000px;
  height: 1000000px;
  left: 0;
  top: 0;
}
.chartjs-size-monitor-shrink > div {
  position: absolute;
  width: 200%;
  height: 200%;
  left: 0;
  top: 0;
}
/*! CSS Used keyframes */
@keyframes chartjs-render-animation {
  from {
    opacity: 0.99;
  }
  to {
    opacity: 1;
  }
}
/*! CSS Used fontfaces */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7SUc.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7SUc.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7SUc.woff2) format("woff2");
  unicode-range: U+1F00-1FFF;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7SUc.woff2) format("woff2");
  unicode-range: U+0370-03FF;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7SUc.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7SUc.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v3/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.spaceShipCard {
  box-sizing: border-box !important;
  width: 100% !important;
  border-radius: 24px !important;
  box-shadow: rgb(18 0 59 / 12%) 1px 4px 24px, rgb(18 0 59 / 8%) 0px 4px 8px !important;
  background-color: rgb(255, 255, 255) !important;
  position: relative !important;
  padding: 32px !important;
  height: 100% !important;
}

.more-bottom {
  position: absolute;
  bottom: 50px;
  right: 20px;
}


