.content {
  max-width: 500px;
  margin: auto;
  background: white;
  padding: 10px;
}

@media (max-width: 960px) {
  .GoalDetail {
    margin-left: 5% !important;
    margin-right: 5% !important;
    margin-top: 0   !important;
  }
}
.GoalDetail {
  // div {
  //   display: block;
  // }
  margin-left: 30%;
  margin-right: 30%;
  margin-top: 15%;

  .title {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }
  .NQ-web{
    margin-bottom: 20px;
  }
  .NQ-web .NQ-card .NQ-card-body{ 
    padding: 4px 10px 0px 10px !important;
    .NQ-left {
    width: 100% !important;
    .NQ-col-1 {
      display: flex;
      align-self: center;
    }
    .NQ-col-2 {
      display: flex;
      align-items: center;
      margin-left: 5px;
      .text-wrap {
      }
    }
  }
  }
  .NQ-web .NQ-card .NQ-card-body .NQ-right {
    width: 25% !important;
    justify-content: space-between !important;
    .NQ-col-1 {
      align-self: center;
    }
  }
  .goal-card {
    box-sizing: border-box !important;
    transition: box-shadow 150ms ease 0s !important;
    border: 2px solid #701aeb !important;
    box-shadow: rgb(18 0 59 / 16%) 2px 24px 36px, rgb(18 0 59 / 6%) 0px 0px 8px !important;
    border-radius: 10px !important;
    padding: 0 !important;
    margin: 0 !important;
    .NQ-card-body {
      .NQ-left-goal {
        width: 100%;
        min-height: 20px;
        display: flex;
        justify-content: space-between;
        padding-left: 5px;
        padding-right: 5px;
        padding: 0 !important;
        span {
          font-size: 17px !important;
        }
      }
    }
  }
}
