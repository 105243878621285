.makeStyles-grid-115{
    padding:  0 !important;
}
@media (max-width: 960px) {
    .add-rain {
        margin: 0 5% !important;
        .sc-oefIU {
            display: block;
            justify-content: center;
        }
        .text-center {
            text-align: center;
            // padding: 0px 60px !important;
        }
        
        .MuiSlider-colorPrimary {
            width: 97% !important;
            margin: 30px 3% !important;
            margin-bottom: 0px !important;
        }
      //  .sc-hhIhEF{
            .makeStyles-grid-36{
                padding: 0px !important;
            }
       // }
    }
}
@media (min-width: 960px) {
    .add-rain {
        margin: 0 7% !important;
        .makeStyles-grid-36{
            padding: 0px !important;
        }
        .sc-hhIhEF {
            display: block;
            justify-content: center;
            .css-1ucoqeb {
                margin: 2% 0% !important;
            }
        }
        .MuiSlider-colorPrimary {
            width: 80% !important;
            margin: 40px 10% !important;
            margin-bottom: 0px !important;
        }

        .firststep {
            margin: 0px 35% !important;
            .css-1ucoqeb {
                margin: 2% 0% !important;
            }

            .css-1yjvs5a{
                margin: 0px !important;
            }
        }
        .nextstep {
            margin: 0px 35% !important;
            
        }
    }
}
