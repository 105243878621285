@media (max-width: 960px) {
  .Home {
    margin-left: 10px !important;
    margin-right: 10px !important;
    overflow-x: hidden;

    .News {
      padding-left: 3% !important;
      padding-right: 3% !important;

      .css-pjo951 {
        padding-left: 0px;
        padding-right: 0px;
      }
    }

    .Portfoliolist {
      margin-left: 0 !important;
      margin-right: 0 !important;

      .NQ-mobile {
        padding: 0px !important;
      }
    }

    .vueNow-calculation {
      .vueNow-calculation-box {
        .lable {
          font-size: 13px !important;
        }

        .count {
          font-size: 13px !important;
        }
      }
    }
  }
}

@media (min-width: 960px) {
  .Home {
    padding-left: 32px;
    padding-right: 32px;
    margin: auto;
    max-width: 1200px;

    .Portfoliolist {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}

@media screen and (max-width: 660px) {
  .Home {
    .css-13bzwy9 {
      .css-k008qs {
        display: flex !important;
        justify-content: center !important;
      }
    }

    .list-logo{
      text-align: center;
    }
  }
}

.Home {
  .hello {
    font-size: 28px;
    line-height: 1.42857;
    color: #aaaaaa;
    font-weight: 400;
  }

  .list-logo{
    padding: 0;
  }

  .list-logo li {
    display: inline-block;
    vertical-align: middle;
  }

  .list-logo li.withSymbol{
    padding: 10px;
  }

  .imageHeightWidthForLogo{
    height: 68px;
    width: 150px;
    margin-top: 10px;
  }

  .AndSymbol{
    font-size: xx-large;
    font-weight: 600;
    color: black;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif !important;
  }
  
  .Portfoliolist {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .vueNow-calculation {
    display: flex;
    width: 100%;

    .vueNow-calculation-box {
      min-width: 150px;
      margin: 5px;
      padding: 20px;
      -webkit-box-align: center;
      align-items: center;
      background: rgb(255, 255, 255);
      border-color: rgb(112, 26, 235);
      border-radius: 8px;
      border-style: solid;
      color: rgb(18, 0, 59);
      cursor: pointer;
      display: block;
      -webkit-box-flex: 1;
      flex-grow: 1;
      font-size: 16px;
      font-weight: 600;
      padding-top: 25px;
      -webkit-box-pack: center;
      justify-content: center;
      margin-right: 8px;
      box-sizing: border-box;
      transition: box-shadow 150ms ease 0s;
      border-width: 2px;
      padding-right: 14px;
      padding-left: 14px;
      box-shadow: rgb(18 0 59 / 16%) 2px 24px 36px, rgb(18 0 59 / 6%) 0px 0px 8px;

      .lable {
        display: block;
        font-size: 15px;
        font-weight: 700;

        letter-spacing: -0.6px;
        color: #250f5a;
        text-align: center;
      }

      .count {
        display: block;
        margin: 0px 0px 16px;
        font-size: 13px;
        font-weight: 700;
        text-align: center;
        letter-spacing: -0.6px;
        color: #250f5a;
        // color: rgb(255, 255, 255);
      }
    }
  }

  .action-area {
    display: flex;
    justify-content: space-between;
    margin-right: 0;
    margin-top: 10px;
    margin-bottom: 10px;

    .title {
      font-size: 20px;
    }

    .action {
      display: flex;
    }
  }

  .w-100 {
    width: 100%;
  }

  @media (max-width: 960px) {

    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    .vueNow-calculation-box {
      display: inline-table;
      /* font-size: 9px; */
      min-width: 150px;
      margin: 5px;
      // margin-left: 0;
      padding: 0 !important;
      // // height: 80px;
      // box-shadow: rgb(18 0 59 / 12%) 0px 2px 6px, rgb(18 0 59 / 4%) 0px -1px 5px;
      // background: rgb(37, 15, 90);
      // border-radius: 16px;
      // padding-top: 20px;

      -webkit-box-align: center;
      align-items: center;
      background: rgb(255, 255, 255);
      border-color: rgb(112, 26, 235);
      border-radius: 8px;
      border-style: solid;
      color: rgb(18, 0, 59);
      cursor: pointer;
      display: block;
      -webkit-box-flex: 1;
      flex-grow: 1;
      font-size: 16px;
      font-weight: 600;

      /* height: 64px; */
      -webkit-box-pack: center;
      justify-content: center;
      margin-right: 8px;
      /* max-height: 64px; */
      box-sizing: border-box;
      transition: box-shadow 150ms ease 0s;
      border-width: 2px;
      padding-right: 14px;
      padding-left: 14px;
      box-shadow: rgb(18 0 59 / 16%) 2px 24px 36px, rgb(18 0 59 / 6%) 0px 0px 8px;

      .lable {
        font-size: 9px !important;
        padding-top: 15px !important;
      }

      .count {
        font-size: 7px !important;
      }
    }

    .action-area {
      margin-right: 0 !important;
      padding: 0px 10px;

      .title {
        font-size: 11px !important;
      }
    }

    .NQ-web {
      padding: 0px 7px 0px 7px;
    }

    .NQ-mobile {
      padding: 0px 7px 0px 7px;
    }
  }
}

.impDoc-card-padding{
  padding: 10px 10px !important;
}
@keyframes reveal {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.loader span {
  animation: reveal 1.5s ease-in-out infinite;
}