/*! CSS Used from: https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css */

@media (min-width: 960px) {
  .makeStyles-container-4 {
    margin: 0 !important;
    padding: 10px 0 !important;
  }

  // .portfolio {
  //   padding-left: 10%;
  //   padding-right: 10%;
  // }
  .portfolio {
    .NQ-web {
      padding-left: 5%;
      padding-right: 5%;
    }
  }

  .portfolio-aligment {
    padding: 0 15%;

    .NQ-web {
      padding: 0px !important;
    }
  }
}
.portfoliop{
  word-break: break-word;
  white-space: pre-wrap !important;
}

.portfolio {
  .NQ-card-header {
    min-height: 50px !important;
    padding: 15px !important;
  }

  .header-right {
    display: flex;
    justify-content: flex-end;

    .NQ-card-button-print {
      height: 23px;
      width: 20px;
      padding: 5px;
      margin-top: 11px;
      margin-right: 10px;
      border-radius: 30px;
      border: 1px solid #ddd;
    }

    .NQ-card-button-offtrack {
      width: 35%;
      margin-top: 5px;
      border: 1px solid #ddd;
      padding: 4px 15px 4px 15px;
      border-radius: 30px;
      min-width: -webkit-fill-available;
      text-align: center;
      margin-top: 11px;
    }
  }

  .cssload-loader {
    margin-top: 250px;
    width: 244px;
    height: 49px;
    line-height: 49px;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    font-family: helvetica, arial, sans-serif;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 30px;
    color: rgb(65, 16, 183);
    letter-spacing: 0.3em;
  }

  .cssload-loader::before,
  .cssload-loader::after {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    background: rgb(65, 16, 183);
    position: absolute;
    animation: cssload-load 0.81s infinite alternate ease-in-out;
    -o-animation: cssload-load 0.81s infinite alternate ease-in-out;
    -ms-animation: cssload-load 0.81s infinite alternate ease-in-out;
    -webkit-animation: cssload-load 0.81s infinite alternate ease-in-out;
    -moz-animation: cssload-load 0.81s infinite alternate ease-in-out;
  }

  .cssload-loader::before {
    top: 0;
  }

  .cssload-loader::after {
    bottom: 0;
  }

  @keyframes cssload-load {
    0% {
      left: 0;
      height: 29px;
      width: 15px;
    }

    50% {
      height: 8px;
      width: 39px;
    }

    100% {
      left: 229px;
      height: 29px;
      width: 15px;
    }
  }

  @-o-keyframes cssload-load {
    0% {
      left: 0;
      height: 29px;
      width: 15px;
    }

    50% {
      height: 8px;
      width: 39px;
    }

    100% {
      left: 229px;
      height: 29px;
      width: 15px;
    }
  }

  @-ms-keyframes cssload-load {
    0% {
      left: 0;
      height: 29px;
      width: 15px;
    }

    50% {
      height: 8px;
      width: 39px;
    }

    100% {
      left: 229px;
      height: 29px;
      width: 15px;
    }
  }

  @-webkit-keyframes cssload-load {
    0% {
      left: 0;
      height: 29px;
      width: 15px;
    }

    50% {
      height: 8px;
      width: 39px;
    }

    100% {
      left: 229px;
      height: 29px;
      width: 15px;
    }
  }

  @-moz-keyframes cssload-load {
    0% {
      left: 0;
      height: 29px;
      width: 15px;
    }

    50% {
      height: 8px;
      width: 39px;
    }

    100% {
      left: 229px;
      height: 29px;
      width: 15px;
    }
  }

  h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }

  a {
    background-color: transparent;
  }

  button,
  input {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }

  button,
  input {
    overflow: visible;
  }

  button {
    text-transform: none;
  }

  button {
    -webkit-appearance: button;
  }

  button::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  button:-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  /*! CSS Used from: Embedded */
  h1 {
    margin: 0px;
    font-size: 28px;
    font-weight: 700;
    line-height: 42px;
  }

  @media (max-width: 560px) {
    h1 {
      margin: 0px;
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
    }
  }

  h3 {
    margin: 0px;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
  }

  .css-h4 {
    margin: 0px;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    color: black;
  }

  .arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 40px;
  }

  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  .css-h_4 {
    margin: 0px;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    color: black;
  }

  p {
    margin: 0px;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
  }

  button {
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    font: inherit;
    margin: 0px;
    outline: none;
    overflow: visible;
    padding: 0px;
    text-transform: none;
  }

  a {
    color: rgb(96, 12, 232);
    cursor: pointer;
    text-decoration: none;
    transition-duration: 150ms;
  }

  a:hover {
    text-decoration: underline;
  }

  a:visited {
    color: rgb(47, 10, 135);
  }

  .css-dpk54r {
    display: flex;
    flex-flow: row wrap;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 1500px;
    padding-left: 12px;
    padding-right: 12px;
    -webkit-box-pack: center;
    justify-content: space-between;
    -webkit-box-align: stretch;
    align-items: stretch;
  }

  @media (max-width: 560px) {
    .css-dpk54r {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  .css-12xmw8i {
    box-sizing: border-box;
    padding-left: 12px;
    padding-right: 12px;
    width: 41.6667%;
    display: block;
  }

  @media (max-width: 560px) {
    .css-12xmw8i {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  @media (max-width: 780px) {
    .css-12xmw8i {
      width: 83.3333%;
      display: block;
    }
  }

  @media (max-width: 560px) {
    .css-12xmw8i {
      width: 100%;
      display: block;
    }
  }

  .css-79elbk {
    position: relative;
    display: block;
  }

  .css-1vj6hdk {
    margin-top: 32px;
    width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    display: none;
    color: rgb(111, 112, 140);
    font-size: 14px;
  }

  @media (max-width: 560px) {
    .css-1vj6hdk {
      margin-bottom: 32px;
      display: flex;
    }
  }

  .css-1sj90s2 {
    overscroll-behavior-y: contain;
    width: 330px;
    box-shadow: rgb(18 0 59 / 16%) 2px 24px 36px, rgb(18 0 59 / 6%) 0px 0px 8px;
  }

  .css-1iuj5ih {
    padding-left: 8px;
  }

  .css-3cyd2n {
    margin-right: 24px;
  }

  .css-fqk8rb {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    padding-bottom: 4px;
    color: rgb(111, 112, 140);
  }

  .css-1crpfoq {
    margin: 0px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    color: rgb(0, 0, 0);
  }

  @media (max-width: 560px) {
    .css-1crpfoq {
      overflow-wrap: break-word;
    }
  }

  .css-gg4vpm5 {
    margin-top: 50px !important;
  }

  .css-gg4vpm {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }

  .css-70qvj9 {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
  }

  .css-1rrlj8d {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    padding-bottom: 4px;
    color: rgb(190, 190, 203);
  }

  .css-yl8xv8 {
    margin: 0px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    color: rgb(255, 255, 255);
  }

  @media (max-width: 560px) {
    .css-yl8xv8 {
      overflow-wrap: break-word;
    }
  }

  .css-1pflw1h {
    margin: 0px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    cursor: pointer;
    border-radius: 32px;
    padding: 2px 12px;
    background-color: transparent;
    color: rgb(190, 190, 203);
  }

  .css-1rr4qq7 {
    flex: 1 1 0%;
  }

  .css-npjedo {
    position: relative;
    display: flex;
    flex-direction: column;
    font-weight: 600;
  }

  .css-npjedo label {
    transition-duration: 150ms;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: rgb(111, 112, 140);
    padding-left: 16px;
  }

  .css-npjedo input {
    outline: 0px;
    font-family: inherit;
    font-weight: 600;
    box-sizing: border-box;
    font-size: 16px;
    height: 64px;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(227, 225, 232);
    padding-left: 16px;
    padding-right: 16px;
  }

  .css-npjedo input:hover,
  .css-npjedo input:focus {
    border-color: rgb(219, 175, 254);
    padding-left: 15px;
    padding-right: 15px;
    border-width: 2px;
  }

  .css-npjedo input:not(:placeholder-shown) {
    border-color: rgb(96, 12, 232);
  }

  .css-npjedo input:disabled {
    border-color: transparent;
    background-color: rgb(239, 238, 242);
  }

  .css-npjedo input:not(:placeholder-shown) + label {
    transform: translateY(0%);
    font-size: 12px;
    top: 12px;
  }

  .css-npjedo input:not(:placeholder-shown) {
    padding-top: 24px;
  }

  .css-npjedo input::-webkit-input-placeholder {
    color: transparent;
  }

  .css-npjedo input::placeholder {
    color: transparent;
  }

  .css-1bu090c {
    position: relative;
    min-height: 101vh;
  }

  .css-vbiqt2 {
    height: 550px !important;
    padding-top: 32px;
    padding-bottom: 24px;
    margin-bottom: 24px;
    background: linear-gradient(354.96deg, rgb(37, 15, 90) 2.35%, rgb(47, 10, 135) 164.11%);
    margin-bottom: 24px;
  }

  .NQ-web {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  @media (max-width: 780px) {
    .css-vbiqt2 {
      padding-top: 10px;
    }
  }

  .css-zm3qx2 {
    color: rgb(255, 255, 255);
  }

  .css-1qm1lh {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: black;
  }

  .css-1mqwiuu {
    display: flex;
    flex-flow: row wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }

  .css-7vh02i {
    flex: 1 1 33%;
    margin-bottom: 24px;
  }

  .css-a7krli {
    margin: -12px 0px 24px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: rgb(111, 112, 140);
  }

  .css-zi6k0i {
    margin-bottom: 24px;
  }

  @media (max-width: 780px) {
    .css-zi6k0i {
      display: none;
      margin-bottom: 0px;
    }
  }

  .css-aolepq {
    margin-bottom: 96px;
  }

  @media (max-width: 780px) {
    .css-aolepq {
      margin-bottom: 64px;
    }
  }

  .css-pdh8l5 {
    display: none;
  }

  @media (max-width: 780px) {
    .css-pdh8l5 {
      display: initial;
    }
  }

  .css-qdceb9 {
    box-sizing: border-box;
    padding-left: 12px;
    padding-right: 12px;
    width: 83.3333%;
    display: block;
  }

  @media (max-width: 560px) {
    .css-qdceb9 {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  @media (max-width: 780px) {
    .css-qdceb9 {
      width: 83.3333%;
      display: block;
    }
  }

  @media (max-width: 560px) {
    .css-qdceb9 {
      width: 100%;
      display: block;
    }
  }

  .css-spvj3g {
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: start;
  }

  .css-1xg25nz {
    flex: 0.6 1 0%;
    display: flex;
    flex-direction: row;
  }

  @media (max-width: 560px) {
    .css-1xg25nz {
      flex: 1 1 0%;
    }
  }

  .css-sryy7y {
    flex: 0.4 1 0%;
  }

  @media (max-width: 560px) {
    .css-sryy7y {
      display: none;
    }
  }

  .css-s7vv29 {
    position: relative;
    height: 230px;
    // margin-bottom: 24px;
    color: rgb(255, 255, 255);
    width: 100%;
  }

  .css-h97r3p {
    display: none;
  }

  @media (max-width: 560px) {
    .css-h97r3p {
      // margin-top: 24px;
      display: block;
    }
  }

  .css-1jqfcnk {
    margin: 0px;
    font-size: 12px;
    line-height: 18px;
    cursor: pointer;
    border-radius: 32px;
    padding: 2px 12px;
    background-color: rgb(112, 26, 235);
    color: rgb(255, 255, 255);
    font-weight: 600;
  }

  .css-12enyi8 {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: rgb(190, 190, 203);
  }

  .css-8g6xxc {
    box-sizing: border-box;
    padding-left: 12px;
    padding-right: 12px;
    width: 33.3333%;
    display: block;
  }

  @media (max-width: 560px) {
    .css-8g6xxc {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  @media (max-width: 780px) {
    .css-8g6xxc {
      width: 83.3333%;
      display: block;
    }
  }

  @media (max-width: 560px) {
    .css-8g6xxc {
      width: 100%;
      display: block;
    }
  }

  .css-15qmbvb {
    box-sizing: border-box;
    padding-left: 12px;
    padding-right: 12px;
    width: 8.33333%;
    display: block;
  }

  @media (max-width: 560px) {
    .css-15qmbvb {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  @media (max-width: 780px) {
    .css-15qmbvb {
      width: 8.33333%;
      display: none;
    }
  }

  @media (max-width: 560px) {
    .css-15qmbvb {
      width: 8.33333%;
      display: block;
    }
  }

  .css-15i3mbm {
    position: absolute;
    overflow: hidden;
    pointer-events: none;
    inset: 0px;
  }

  .css-1urnvyu {
    position: absolute;
    top: 0px;
    left: 0px;
    min-height: 80px;
    max-height: 80px;
    min-width: 100%;
  }

  @media (max-width: 780px) {
    .css-1urnvyu {
      border-color: rgb(112, 26, 235);
      border-bottom-style: solid;
      border-bottom-width: 1px;
    }
  }

  .css-1t55g3u {
    position: absolute;
    top: 88px;
    left: 16px;
    width: 48px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
  }

  .css-1t55g3u p {
    margin: 0px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    opacity: 0.4;
    color: rgb(255, 255, 255);
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    white-space: nowrap;
  }

  @media (max-width: 780px) {
    .css-1t55g3u {
      position: initial;
      width: initial;
      display: initial;
    }

    .css-1t55g3u p {
      writing-mode: initial;
      transform: initial;
      white-space: initial;
      width: 150px;
      height: 48px;
      padding-left: 72px;
      padding-top: 16px;
      line-height: 1.4em;
      -webkit-box-align: center;
      align-items: center;
      display: flex;
    }
  }

  .css-muvk5k {
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 104px;
  }

  .css-1f8d78s {
    pointer-events: auto;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 48px;
    width: 48px;
    border-radius: 48px;
    background: rgba(112, 26, 235, 0.6);
    opacity: 0.4;
    cursor: initial;
  }

  .css-1j9lxat {
    pointer-events: auto;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 48px;
    width: 48px;
    border-radius: 48px;
    cursor: pointer;
    background: rgba(112, 26, 235, 0.6);
  }

  .css-1v87gka {
    position: fixed;
    overflow-y: scroll;
    pointer-events: auto;
    overscroll-behavior-y: contain;
    height: 100vh;
    width: 330px;
    /* left: 400px; */
    z-index: 9999;
    /* left: 0 ; */
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(18, 0, 59, 0.16) 2px 24px 36px, rgba(18, 0, 59, 0.06) 0px 0px 8px;

    /* transform: translate3d(-100%, 0px, 0px) ; */
    transition-duration: 250ms;
    transition-property: visibility, transform;
    transition-timing-function: ease;
  }

  .transform-show {
    transform: none;
  }

  .transform-hide {
    transform: translate3d(-100%, 0px, 0px);
  }

  @media screen and (min-width: 1900px) {
    .css-1v87gka {
      left: 69px;
    }
  }

  .css-1v87gka::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  .css-19midj6 {
    padding: 16px;
    padding-left: 20px;
  }

  .css-v84hdm {
    margin: 0px 0px 4px;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    margin: 0px;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
  }

  .css-mub4t9 {
    margin: 0px 0px 24px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: rgb(111, 112, 140);
  }

  .css-fqzl36 {
    padding: 16px 12px;
    user-select: none;
    cursor: pointer;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
  }

  .css-1nfx0y4 {
    margin-right: 15px;
    display: flex;
  }

  .css-1w2tmy2 {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    transition-duration: 150ms;
  }

  .css-tuoeod {
    border-color: rgb(227, 225, 232);
    border-top-style: solid;
    border-top-width: 2px;
  }

  .css-odqti0 {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding-top: 12px;
    padding-right: 16px;
    padding-bottom: 12px;
    padding-left: 50px;
    border-color: rgb(239, 238, 242);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    color: rgb(0, 0, 0);
  }

  .css-odqti0.active,
  .css-odqti0:hover {
    background-color: rgb(243, 228, 255);
    color: rgb(147, 14, 255);
    text-decoration: none;
  }

  .css-odqti0:hover {
    cursor: pointer;
  }

  .css-19onubp {
    margin: 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    flex: 1 1 0%;
    -webkit-box-flex: 1;
  }

  .active .css-19onubp {
    font-weight: 600;
  }

  .css-14kvi34 {
    margin: 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex: 0.4 1 0%;
    -webkit-box-pack: end;
    justify-content: flex-end;
  }

  .css-7h0zwy {
    display: inline-block;
    margin-left: 4px;
  }

  .active .css-7h0zwy {
    font-weight: 600;
  }

  .css-156kcmz {
    pointer-events: auto;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 48px;
    width: 48px;
    border-radius: 48px;
    cursor: pointer;
    position: absolute;
    top: 45px;
    transition-duration: 250ms;
    transition-property: left;
    transition-timing-function: ease;
    animation-name: animation-zvmmj9;
    animation-duration: 500ms;
    transform: rotate(180deg);
    left: 16px;
    background: rgba(112, 26, 235, 0.6);
  }

  .css-156kcmz2 {
    pointer-events: auto;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 48px;
    width: 48px;
    border-radius: 48px;
    cursor: pointer;
    position: absolute;
    top: 16px;
    transition-duration: 250ms;
    transition-property: left;
    transition-timing-function: ease;
    animation-name: animation-zvmmj9;
    animation-duration: 500ms;
    transform: rotate(180deg);
    right: -1;
    background: rgb(112, 26, 235);
  }

  @media (max-width: 780px) {
    .css-156kcmz {
      animation-name: animation-1f2pbcz;
    }
  }

  .css-4rmlxi {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .css-1iet05r {
    padding: 16px 12px;
    user-select: none;
    cursor: pointer;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
  }

  .css-5hicrt {
    margin-right: 12px;
  }

  .css-1ef9l9a {
    margin-right: 2px;
    transform: rotate(180deg);
  }

  .css-1ef9l9a path {
    fill: rgb(255, 72, 64);
  }

  .css-1d4zyaq {
    margin-right: 2px;
  }

  .css-1d4zyaq path {
    fill: rgb(8, 210, 178);
  }

  .css-1x71eut h4 {
    margin-bottom: 16px;
  }

  .css-1x71eut p {
    margin-bottom: 16px;
    color: rgb(81, 81, 108);
  }

  /*! CSS Used from: Embedded */
  .chartjs-render-monitor {
    animation: chartjs-render-animation 0.001s;
  }

  .chartjs-size-monitor,
  .chartjs-size-monitor-expand,
  .chartjs-size-monitor-shrink {
    position: absolute;
    direction: ltr;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    pointer-events: none;
    visibility: hidden;
    z-index: -1;
  }

  .chartjs-size-monitor-expand > div {
    position: absolute;
    width: 1000000px;
    height: 1000000px;
    left: 0;
    top: 0;
  }

  .chartjs-size-monitor-shrink > div {
    position: absolute;
    width: 200%;
    height: 200%;
    left: 0;
    top: 0;
  }

  /*! CSS Used keyframes */
  @-webkit-keyframes animation-zvmmj9 {
    0% {
      transform: translate3d(-100%, 0px, 0px) rotate(180deg);
    }

    100% {
      transform: translate3d(0px, 0px, 0px) rotate(180deg);
    }
  }

  @keyframes animation-zvmmj9 {
    0% {
      transform: translate3d(-100%, 0px, 0px) rotate(180deg);
    }

    100% {
      transform: translate3d(0px, 0px, 0px) rotate(180deg);
    }
  }

  @-webkit-keyframes animation-1f2pbcz {
    0% {
      transform: translate3d(0px, -100%, 0px) rotate(180deg);
    }

    100% {
      transform: translate3d(0px, 0px, 0px) rotate(180deg);
    }
  }

  @keyframes animation-1f2pbcz {
    0% {
      transform: translate3d(0px, -100%, 0px) rotate(180deg);
    }

    100% {
      transform: translate3d(0px, 0px, 0px) rotate(180deg);
    }
  }

  @keyframes chartjs-render-animation {
    from {
      opacity: 0.99;
    }

    to {
      opacity: 1;
    }
  }

  /* side bar media query */

  @media (min-width: 961px) {
    /* big landscape tablets, laptops, and desktops */
    .css-1v87gka {
      left: 68.4px;
    }
  }

  @media (max-width: 960px) {
    /* big landscape tablets, laptops, and desktops */
    .css-1v87gka {
      left: 0px;
    }

    .mobile-view-grid {
      margin-left: -10px;
      margin-top: 10px;
    }

    .space-between {
      justify-content: space-between;
    }

    .css-vbiqt2 {
      height: 380px !important;

      .css-spvj3g {
        margin-bottom: 24px;
      }

      .portfolio h1 {
        font-size: 19px;
      }
    }
  }

  /* side bar media query */
  .css-vbiqt2 {
    margin-bottom: 0 !important;
  }

  .portfolio-detail-card {
    border: 2px solid #4110b7;
    border-radius: 16px;
    font-size: 11px;
    height: 80px;
    margin-top: 10px;

    p {
      padding: 20px;
      padding-bottom: 0;
      padding-top: 13px;
    }
  }

  .customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  .customers th {
    border-bottom: 1px solid #ddd;
    padding: 8px;
    padding-left: 0;
  }

  .customers tbody td {
    font-size: 14px;
    line-height: 2;
    color: #aaaaaa;
    font-weight: 400;
    padding-right: 5px;
    padding-bottom: 3px;
    padding-top: 3px;
  }

  .customers tfoot td {
    border-top: 1px solid #ddd;
    padding: 8px;
    padding-left: 0;

    text-align: left;

    font-size: 14px;
    line-height: 1.42857;

    font-weight: 700;
  }

  // .customers tr:nth-child(even) {
  //   background-color: #f2f2f2;
  // }

  .customers {
    // .progress > .progress-type {
    //   position: absolute;
    //   left: 0px;
    //   font-weight: 800;
    //   padding: 3px 30px 2px 10px;
    //   color: rgb(255, 255, 255);
    //   background-color: rgba(25, 25, 25, 0.2);
    // }
    // .progress > .progress-completed {
    //   position: absolute;
    //   right: 0px;
    //   font-weight: 800;
    //   padding: 3px 10px 2px;
    // }
    .progress-bar {
      // border-radius: 60px;
      height: 15px;
    }

    .progress-bar-success {
      background-color: green;
    }
  }

  .customers th {
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: left;
    color: #333 !important;
    width: 30.33%;
    font-size: 14px;
    line-height: 1.42857;

    font-weight: 700;
  }

  th:nth-child(1) {
    width: 30% !important;
  }

  th:nth-child(2) {
    width: 30% !important;
  }

  th {
    width: 14% !important;
    text-align: left;
  }

  .customers td:nth-child(1) {
    display: flex;
    justify-content: space-between;
  }
}

.accordion-panel {
  height: calc(100vh - 20vh);
  overflow: scroll;
  overflow-y: auto;
}

.changeportfolio {
  margin-left: 10px;

  color: #fff;
  pointer-events: auto;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  border-radius: 48px;
  cursor: pointer;
  background: rgba(112, 26, 235, 0.6);
}

.unselectedchangeportfolio {
  margin-left: 10px;

  color: #fff;
  pointer-events: auto;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  border-radius: 48px;
  cursor: pointer;
  background: transparent;
}

//table

//table

.aggrid-progress {
  .progress {
    position: relative;
    height: 25px;
  }

  .progress > .progress-type {
    position: absolute;
    left: 0px;
    font-weight: 800;
    padding: 3px 30px 2px 10px;
    color: rgb(255, 255, 255);
    background-color: rgba(25, 25, 25, 0.2);
  }

  .progress > .progress-completed {
    position: absolute;
    right: 0px;
    font-weight: 800;
    padding: 3px 10px 2px;
  }

  .NQ-web {
    .NQ-card {
      .NQ-card-body {
        display: block !important;
        padding: 0 10px !important;
      }
    }
  }
}

@media (max-width: 960px) {
  .portfolio {
    padding-left: 3%;
    padding-right: 3%;

    .portfolio-detail-card {
      width: 95% !important;
    }

    .NQ-card-body {
      padding: 0px !important;
      .chart {
        margin-top: 10px;
      }
    }
  }
}

.portfolio {
  .NQ-card-body {
    padding: 0px !important;
    padding-left: 10px !important;
    .chart {
      margin-top: 10px;
    }
  }

  .ag-cell-focus {
    border: none !important;
  }
}

.d-block {
  display: block !important;
}

.t-center {
  text-align: center;
}

.header_label {
  margin: 0px;
  font-style: normal;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif;
  font-weight: 700;
  color: rgb(18, 0, 59);
}

@media (min-width: 801px) {
  .portfoliio-mobile-card {
    display: none;
  }

  .portfoliio-web-view {
    display: block !important;
  }
}

@media (max-width: 800px) {
  .portfolio {
    .chart {
      padding-bottom: 10px !important;
      padding-top: 10px !important;
    }
  }

  .portfoliio-web-view {
    display: none !important;
  }

  .portfoliio-mobile-card {
    display: block;
    box-sizing: border-box;
    position: relative;
    width: 97%;
    height: 120px;
    box-shadow: rgb(18 0 59 / 12%) 0px 2px 6px, rgb(18 0 59 / 4%) 0px -1px 5px;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    overflow: hidden;
    border: 1px solid #270d66;
    background: #dddddd1c;

    .portfolio-mobile-card-header {
      background: #260f5d;
      padding: 5px;
      color: #fff;
      padding-left: 10px;
    }
  }
}

.test1 {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding: 24px;
}

.d-none {
  display: none;
}

/////////////////////////////@at-root

@media only screen and (max-width: 800px) {
  /* Force table to not be like tables anymore */
  #no-more-tables table,
  #no-more-tables thead,
  #no-more-tables tbody,
  #no-more-tables th,
  #no-more-tables td,
  #no-more-tables tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  #no-more-tables thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  // #no-more-tables tr { border: 1px solid #ccc; }

  #no-more-tables td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
    white-space: normal;
    text-align: left;
  }

  /*
Label the data
*/
  #no-more-tables td:before {
    content: attr(data-title);
  }
}

.w-100 {
  width: 100%;
}

#no-more-tables {
  th {
    padding: 20px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    background: #f5f5f8 !important;
    border: none !important;
    margin: 0px !important;
    border: none !important;
  }

  td {
    // padding: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
    border-bottom: 1px solid #f5f5f8;
  }
}

@media (max-width: 800px) {
  #no-more-tables {
    th {
      padding: 20px !important;
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      background: #f5f5f8 !important;
      border: none !important;
      margin: 0px !important;
      border: none !important;
    }

    td {
      // padding: 10px;
      padding-bottom: 5px;
      padding-top: 5px;
      border-bottom: 1px solid #f5f5f8;
    }

    tbody {
      tr {
        margin-bottom: 10px;
        //border-top: 4px solid #ddd;
        padding: 20px;
        padding-bottom: 0px;
        padding-top: 0px;

        td {
          padding-bottom: 5px;
          padding-top: 5px;
          min-height: 22px;
        }

        .mobile-text {
          margin: 0px;
          font-style: normal;
          font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif;
          font-size: 13px;
          line-height: 22px;
          color: rgb(18, 0, 59);
          font-weight: 600; 
        }
      }

      tr:nth-child(1) {
        border: none !important;
      }
    }

    td:before {
      position: absolute;
      top: 4px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      text-align: left;
      font-weight: 600;
      color: hsl(240deg 18% 85%);
    }

    td:nth-last-child(1) {
      border-bottom: 1px solid #4110b7;
    }
  }
}

@media (min-width: 801px) {
  #no-more-tables {
    th {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      background: #f5f5f8 !important;
      border: none !important;
      margin: 0px !important;
      border: none !important;
      text-align: left;
      padding-left: 10px !important;
      padding-right: 10px !important;
    }

    td {
      padding: 10px;
      padding-bottom: 5px;
      padding-top: 5px;
      border-bottom: 1px solid #f5f5f8;
    }

    tbody {
      td:nth-child(2) {
        width: 300px;
      }
    }
  }
}

.nowrap {
  white-space: nowrap;
  text-align: center;
}

.theadWrap {
  white-space: nowrap;
  text-align: center !important;
}

@media (max-width: 800px) {
  .makeStyles-content-3 {
    padding-top: 0 0 !important;

    .portfolio {
      padding-left: 0 !important;
      padding-right: 0 !important;

      .NQ-web {
        padding-left: 3% !important;
        padding-right: 3% !important;
      }
    }
  }
}

.reset {
  svg {
    path {
      fill: #e83333 !important;
    }
  }
}

@media (min-width: 961px) {
  .web-assetClass {
    display: block !important;
    float: right !important;
  }

  .header-value {
    font-size: 22px !important;
    color: rgb(51, 51, 51) !important;
  }
}

@media (max-width: 960px) {
  .makeStyles-content-3 {
    padding: 0px 0px !important;
  }

  .NQ-card-header {
    .web-assetClass {
      float: none !important;
      padding-bottom: 0px !important;
      border-top: 1px solid #270e61 !important;
      margin-top: 5px !important;
      padding-top: 5px !important;
      display: flex !important;
      justify-content: space-between !important;

      b {
        font-size: 14px;
        line-height: 0;
      }

      .header-value {
        font-size: 12px !important;
        color: rgb(51, 51, 51) !important;
        line-height: 1 !important;
      }

      svg {
        height: 20px !important;
        margin: 0;
        margin-top: 3px;
      }
    }
  }
}

.action {
  .close {
    svg {
      height: 25px !important;
      padding-top: 5px;
    }
  }
}

.portfolio {
  .NQ-card-header {
    min-height: 20px !important;

    b {
      margin: 0px;
      font-style: normal;
      font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif;
      font-size: 16px;
      line-height: 26px;
      color: rgb(18, 0, 59);
      font-weight: 700;
      margin-left: 13px;
    }
  }
}

.WIcCe {
  box-sizing: border-box;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 16px;
  padding-bottom: 16px;

  b {
    margin: 0px;
    font-style: normal;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 12px;
    line-height: 28px;
    text-align: left;
    color: rgb(111, 112, 140);
    font-weight: 600;
  }

  .header-value {
    margin: 0px;
    font-style: normal;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif;
    text-align: left;
    color: black;
    font-weight: 700;
    margin-bottom: 16px;
    //
    padding-bottom: 26px;
  }

  .summery-border-bottom {
    border-bottom: 1px solid #ddd !important;
  }
}

.WIcCe1 {
  box-sizing: border-box;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 16px;
  padding-bottom: 16px;

  b {
    margin: 0px;
    font-style: normal;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 12px;
    line-height: 28px;
    text-align: left;
    color: rgb(111, 112, 140);
    font-weight: 600;
  }

  .header-value {
    margin: 0px;
    font-style: normal;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif;
    text-align: left;
    color: black;
    font-weight: 700;
    margin-bottom: 16px;
    //
    padding-bottom: 26px;
  }

  .summery-border-bottom {
    border-bottom: 1px solid #ddd !important;
  }
}

.portfolio-dashboard-layout {
  min-height: 200px;
  margin-left: 15%;
  margin-right: 15%;

  .NQ-web {
    padding: 0px !important;

    .WIcCe {
      padding: 0px !important;

      .chart {
        padding: 10px !important;

        canvas {
          width: 100% !important;
        }
      }
    }

    .assetStructure {
      .WIcCe {
        padding: 30px !important;

        canvas {
          width: 300px !important;
          height: 316px !important;
          margin-left: 17% !important;
        }
        
        
      }
      .WIcCe1 {
        padding-top: 13px !important;
      }
    }
  }
}

@media (max-width: 960px) {
  .portfolio-dashboard-layout {
    min-height: 200px;
    margin-left: 0%;
    margin-right: 0%;

    .NQ-web {
      padding: 0px !important;
    }
  }
}

.myasset {
  .WIcCe {
    min-height: 522px !important ;
    max-height: 522px !important;
    overflow: auto !important;
  }

  .my-assettab-content,
  table {
    width: 100%;
    border-spacing: 0px;

    .imgtd {
      height: 3.5rem;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 2rem;
      }
    }

    .text-formate {
      text-transform: uppercase;
      font-weight: 600;
    }

    .text-right {
      text-align: right;
    }

    .text-center {
      text-align: center;
    }

    td {
      padding: 3px 0;
      border-bottom: 2px solid #ddd;
    }

    tr:nth-last-child(1) {
      td {
        border: none !important;
      }
    }
    td:nth-child(1) {
      padding-left: 10px;
      padding-right: 10px;
    }

    .line-height-0-5 {
      line-height: 0.5;
    }

    .qty-label {
      font-size: 12px;
      font-weight: 600;
    }

    .m-t-9 {
      margin-top: -9px;
    }

    .star {
      width: 20%;
      font-size: 29px;
    }

    .m-t-13 {
      margin-top: 13px;
    }

    .text-red {
      color: #b40f09;
    }

    .text-green {
      color: #59be05;
    }
  }
}

.portfolio {
  .NQ-web {
    margin-bottom: 0px !important;
  }
  .portfolioPerformance {
    .WIcCe {
      min-height: 444px !important;
      max-height: 462px !important;
    }
    .portfolioPerformancechart {
      svg {
        rect {
          fill: #3a276a !important;
        }

        text {
          fill: #333 !important;
        }

        path {
          stroke-width: 1px !important;
        }
      }
    }
  }

  .assetStructureChart {
    svg {
      path {
        stroke-width: 1px !important;
      }
      text {
        fill: #333 !important;
      }
    }
  }

  .portfolioValuechart {
    margin: 2px 5px;
    svg {
      path {
        stroke-width: 1px !important;
      }
    }
  }
}

.report{
  color: blue; 
  text-align: left;
  margin-top: 15px;
  margin-right: 10px;
  cursor: pointer;
}

.graph{
  height: 600px;
}
.graph1{
  height: 500px;
}
