body {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif !important;
}

.next-steps .fa-link {
  margin-right: 5px;
}

.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}
.no-padding-in-portfolio {
  padding-right: 0px !important;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

/* zeeshan */
.MuiContainer-root {
  margin: 0px;
}

.ps__thumb-y {
  background-color: #250f5a !important;
}

.mobileViewDoughnut {
}
.mobileViewDoughnut .chartjs-render-monitor {
  padding: 20px !important;
}
@media (min-width: 960px) {
  .makeStyles-mainPanel-2 {
    width: calc(100% - 40px) !important;
  }
  .jss2 {
    width: calc(100% - 40px) !important;
  }
}

@media screen and (min-width: 1300px) {
  .ps {
    margin-right: 0px !important;
  }
}

@media (min-width: 600px) {
  @media (min-width: 1300px) {
    .makeStyles-container-4 {
      margin-left: 120px !important;
      margin-right: 120px !important;
    }
  }
}

.MuiContainer-root {
  width: 100%;
  display: block;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}
@media (min-width: 320px) {
  .css-naxvie h1 {
    margin: 0px !important;
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 36px !important;
  }

  .makeStyles-content-3 {
    padding: 0px 0px 0px 20px !important;
    padding-bottom: 100px !important;
    min-height: calc(100vh - 123px) !important;
  }
  .makeStyles-container-4 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media (min-width: 961px) {
  .jss2 {
    width: calc(100% - 50px) !important;
  }
  .MuiContainer-root {
    padding-left: 30px !important;
  }
  .no-padding-in-portfolio {
  }
}
@media (min-width: 1024px) {
  .no-padding-in-portfolio {
    padding-right: 0px !important;
  }
}
@media (min-width: 1025px) {
  .no-padding-in-portfolio {
    padding-right: 0px !important;
    padding-left: 30px !important;
  }
}
@media (min-width: 1281px) {
}

@media (max-width: 960px) {
  .makeStyles-mainPanel-2 {
    width: calc(100% - 0px) !important;
  }
  .MuiContainer-root {
    padding-left: 0px !important;
  }
  .jss2 {
    width: calc(100% - 0px) !important;
  }
  .no-padding-in-portfolio {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .mobileViewDoughnut {
    display: flex;
  }
  .mobileViewDoughnut .chartjs-render-monitor {
    padding: none !important;
    height: 333px !important;
    width: 500px !important;
  }
  /* Doughnut */
}

@media (max-width: 450px) {
  .potcard {
    .e-accumulationchart {
      rect {
        width: max-content !important;
      }
    }
    #pie-chart_datalabel_Series_0 {
      text {
      }
      path {
      }
    }
  }
}

@media (max-width: 500px) {
  .vertical-menu div img {
    max-width: 50px !important;
  }

  .mobileViewDoughnut {
    display: block;
  }
}

@media only screen and (min-device-width: 200px) and (max-device-width: 379px) {
  .vertical-menu div img {
    max-width: 50px !important;
  }

  .mobileViewDoughnut {
    display: block;
  }
  .mobileViewDoughnut .chartjs-render-monitor {
    padding: none;
    height: 150px !important;
    width: 250px !important;
  }
}

@media only screen and (min-device-width: 380px) and (max-device-width: 600px) {
  .vertical-menu div img {
    max-width: 50px !important;
  }

  .mobileViewDoughnut {
    display: block;
  }
  .mobileViewDoughnut .chartjs-render-monitor {
    padding: none !important;
    height: 333px !important;
    width: 350px !important;
  }
}
.text-center {
  text-align: center;
}

.MuiBackdrop-root {
}
.MuiDialogContent-root {
  background: #fff !important;
}
.MuiDialogTitle-root {
  background-color: #fff;
}
.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.MuiDialog-paper {
  animation-duration: 0.5s;
  animation-name: slidein;
  animation-direction: alternate;
  animation-iteration-count: 1;
}
@keyframes slidein {
  from {
    margin-bottom: 100%;
    width: 300%;
  }

  to {
    margin-bottom: 0%;
    width: 100%;
  }
}

.linr-chart-card {
  width: 66%;
}

.text-EllipsisTitle {
  span {
    color: #333;
    margin-bottom: 10px;
    font-size: 30px;
    font-weight: 600;
  }
}
.text-EllipsisText {
  margin-top: 10px !important;
  span {
    color: rgb(111, 112, 140);
  }
}

.stock {
  .css-vy2pbh {
    height: 90% !important;
  }
}
.MuiList-padding {
  a {
    border-bottom: none !important;
  }
}

.MuiTooltip-popper {
  div {
    padding: 13px;
    font-size: 12px;
    background-color: rgb(37, 15, 90);
  }
  .makeStyles-arrow-30 {
    color: #250f5a !important;
  }
}

.MuiContainer-maxWidthLg {
  max-width: 100% !important;
}

@media (max-width: 960px) {
  .NQ-btn {
    font-size: 12px !important;
  }
}

.NQ-btn {
  display: flex;
  box-sizing: border-box;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: fit-content;
  font-weight: 600;
  border-width: 3px;
  border-style: solid;
  border-color: transparent;
  cursor: pointer;
  line-height: 22px;
  transition-duration: 150ms;
  height: 32px;
  min-width: 96px;
  font-size: 14px;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 16px;
  color: rgb(255, 255, 255);

  outline: 0 !important;
  margin-left: 2px;
  margin-right: 2px;
}

.NQ-btn-blue {
  background-color: rgb(96, 12, 232);
}
.NQ-btn-blue:hover {
  background-color: rgb(65, 16, 183) !important;
}
.NQ-btn-blue:focus {
  border-color: rgb(219, 175, 254) !important;
  outline: none !important;
  background-color: rgb(65, 16, 183) !important;
}
.NQ-btn-success {
  background-color: rgb(105, 230, 136);
}
@media (max-width: 960px) {
  .NQ-card {
    margin-right: 0px !important;
    .NQ-card-body {
      .NQ-left {
      }
      .NQ-right {
      }

      .NQ-card-button {
        span {
        }
      }
    }
    h6 {
    }
    h5 {
    }
  }
}

.NQ-web {
  .NQ-card {
    border: 1px solid #ddd;
    background-color: #fff;
    margin-right: 0;
    min-height: 20px;
    box-shadow: rgb(18 0 59 / 12%) 0px 2px 6px, rgb(18 0 59 / 4%) 0px -1px 5px;
    margin-bottom: 10px;
    border-radius: 16px;
    .NQ-card-header {
      min-height: 50px;
      border-bottom: 1px solid #ddd;
    }
    .NQ-card-body {
      display: flex;
      justify-content: space-between;
      padding: 10px;

      .NQ-left {
        width: 45%;
        min-height: 20px;
        display: flex;
        justify-content: space-between;
        padding-left: 5px;
        padding-right: 5px;
      }
      .NQ-right {
        width: 55%;
        min-height: 20px;
        display: flex;
        justify-content: space-around;
        padding-left: 5px;
        padding-right: 5px;
      }

      .NQ-card-button {
        width: 70px;
        margin-top: 5px;
        border: 1px solid #ddd;
        padding: 2px 6px 2px 6px;
        border-radius: 30px;
        text-align: center;
        span {
          font-size: 12px;
          color: green;
        }
      }
    }
  }
}
.NQ-mobile {
  .NQ-card {
    box-sizing: border-box;
    width: 100%;
    border-radius: 24px;
    box-shadow: rgb(18 0 59 / 12%) 1px 4px 24px, rgb(18 0 59 / 8%) 0px 4px 8px;
    background-color: rgb(255, 255, 255);
    position: relative;
    padding: 32px;
    min-height: 350px;
    height: 100%;
    margin-bottom: 20px;
    .NQ-card-body {
      display: block;

      .NQ-row-1 {
        display: flex;
        justify-content: space-between;
      }
      .NQ-row-2 {
        display: flex;
        justify-content: center;
      }
      .NQ-row-3 {
        display: flex;
        justify-content: flex-end;
        padding-right: 20px;
      }

      .NQ-card-button {
        width: 35%;
        margin-top: 5px;
        border: 1px solid #ddd;
        padding: 2px 6px 2px 6px;
        border-radius: 30px;
        min-width: -webkit-fill-available;
        text-align: center;
        span {
          font-size: 12px;
          color: green;
        }
      }
    }
  }
}
.balanceAmount {
  line-height: 46px;
  margin: 0px;
  font-style: normal;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif;
  font-weight: 700;
  color: rgb(18, 0, 59);
  font-size: 32px;
}

.align-self-center {
  align-self: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.f-weight-600 {
  font-weight: 600;
}
.m-0 {
  margin: 0;
}
.fit-content {
  width: fit-content;
}

.text-success {
  color: green;
  cursor: pointer;
}
.pointer {
  cursor: pointer;
}
.pointer:hover {
  color: #4110b7;
}

.ps__rail-y {
  width: 7px !important;
}

.makeStyles-container-4 {
  margin: 0 !important;
  padding: 0 !important;
}

.Homecard,
.NewsCard {
  margin-bottom: 20px;
  margin-right: 0px;

  .scrollbar {
    overflow: scroll;
    margin-left: 0 !important;
    margin-right: 0 !important;
    overflow-y: hidden;
    overflow-x: auto;
    display: flex;
    padding-bottom: 0 !important;
  }

  .force-overflow {
    display: flex;
    width: 100%;
    min-height: 130px;
  }

  @media (max-width: 960px) {
    .force-overflow {
      min-height: 90px !important;
    }
    .Homecard {
      margin-right: 15px !important;
    }
  }

  /*
 *  STYLE 1
 */

  #style-1::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  #style-1::-webkit-scrollbar {
    background-color: #f5f5f5;
    width: 0px;
    height: 5px;
  }

  #style-1::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #701aeb;
  }
}

@media (max-width: 720px) {
  .jss2 {
    height: calc(100vh - 87.4px) !important;
    overflow-x: hidden !important;
    .jss3 {
      padding: 30px 0px !important;
      .jss4 {
        padding-left: 0px !important;
        padding-right: 0px !important;
      }
    }
  }
  .container {
    height: calc(100vh - 87.4px) !important;
  }

  .NQ-Input-Text .css-npjedo {
    width: 330px !important;
  }
}

.isError {
  .NQ-Input-Text {
    input {
      border: 1px solid #a01818 !important;
      border-left: 6px solid #a01818 !important;
    }
    label {
      color: #a01818 !important;
    }
    .errormsg {
      color: #a01818 !important;
      font-size: 13px;
    }
  }

  .onerror {
    min-height: 0px;
    background: #d82525;
    display: flex;
    justify-content: start;
    margin-top: -17px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #fff;
    padding-left: 5px;
    padding-top: 12px;
    padding-bottom: 4px;
    z-index: 1;
  }
}
.aggridcell-dropdown {
  .basic-single {
    overflow: visible !important;
    position: relative !important;
    z-index: 3 !important;
  }
  .select__clear-indicator {
    display: none;
  }
}
.celldropdown {
  .basic-single {
    overflow: visible !important;
    position: relative !important;
    z-index: 3 !important;
  }
  .select__clear-indicator {
    display: none;
  }
}
.aggridcell-action-button {
  svg {
    height: 16px !important;
    margin-top: 3px !important;
  }
}
.grid-dropdown-cell {
  position: relative !important;
  z-index: 31 !important;
  overflow: visible !important;
  z-index: 2 !important;
  .css-yk16xz-control {
    min-height: 20px !important;
    height: 40px !important;
    border-radius: 1px;
    box-shadow: none !important;
    border-top: none !important;
    border-bottom: none !important;
    border-left: none !important;
    .css-1uccc91-singleValue {
      padding-left: 12px !important;
    }
    .css-g1d714-ValueContainer {
      padding: 0px !important;
      .css-1wa3eu0-placeholder {
        top: 33.5% !important;
        padding-left: 12px !important;
      }
      .css-b8ldur-Input {
        height: 25px !important;
      }
    }
    .css-tlfecz-indicatorContainer {
      padding: 0px !important;
    }
  }
  .select__control--is-focused {
    min-height: 25px !important;
    height: 40px !important;
    border-radius: 1px;
    .css-1gtu0rj-indicatorContainer {
      padding: 0px !important;
    }
    .css-g1d714-ValueContainer {
      padding: 0px !important;
    }
    .css-1wa3eu0-placeholder {
      top: 55% !important;
    }
    .css-b8ldur-Input {
      height: 25px !important;
    }
  }

  .select__menu {
    margin: 0px !important;
    .select__option {
      position: relative !important;
      overflow: visible !important;
      z-index: 333;
      background: transparent !important;
      color: #333 !important;
      padding: 0px 0px 0px 10px !important;
    }
  }
}

.ag-floating-bottom-container {
  .cellprogresbar {
    .aggrid-progress {
      display: none !important;
    }
  }
}
.portfolio-grid {
  .cellmultiplevalue {
    .double-line-content {
      line-height: 0.5 !important;
      font-size: 11px !important;
    }
  }
}
.select__control--is-focused {
  border-color: transparent !important;
}
.css-yk16xz-control:hover {
  border-color: transparent !important;
}
.ag-theme-alpine .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-alpine .ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-alpine .ag-ltr .ag-has-focus .ag-full-width-row.ag-row-focus .ag-cell-wrapper.ag-row-group,
.ag-theme-alpine .ag-ltr .ag-cell-range-single-cell,
.ag-theme-alpine .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle,
.ag-theme-alpine .ag-rtl .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-alpine .ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-alpine .ag-rtl .ag-has-focus .ag-full-width-row.ag-row-focus .ag-cell-wrapper.ag-row-group,
.ag-theme-alpine .ag-rtl .ag-cell-range-single-cell,
.ag-theme-alpine .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
  z-index: 3 !important;
}
.ag-row-hover:hover {
  border-bottom: 3px solid #4110b7 !important;
}
.ag-theme-alpine .ag-ltr .ag-cell {
  font-weight: 400 !important;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif !important;
}

.aggrid-progress {
  .progress-title {
    font-size: 16px;
    font-weight: 700;
    color: #333;
    margin: 0 0 20px;
  }
  .progress {
    background: #f5f2f257;
    border-radius: 0;
    box-shadow: none;
    overflow: visible;
    height: 65px;
    margin-right: -20px;
  }
  .progress .progress-bar {
    position: relative;
    -webkit-animation: animate-positive 2s;
    animation: animate-positive 2s;
  }
  .progress .progress-bar:after {
    content: "";
    display: inline-block;
    width: 9px;
  }
  .progress .progress-value {
    display: block;
    font-size: 12px;
    font-weight: 600;
    position: absolute;
    left: 5px;
  }
  @-webkit-keyframes animate-positive {
    0% {
      width: 0;
    }
  }
  @keyframes animate-positive {
    0% {
      width: 0;
    }
  }
}

.cellprogress {
  margin-left: -15px;
}

.Home {
  .ebARNv {
    line-height: 32px;
  }
  .home-chart {
    .chart {
      canvas {
        height: 230px !important;
        width: 230px !important;
      }
    }
  }
}

.moreLink {
  margin: 0px !important;
  font-size: 12px !important;
  line-height: 18px !important;
  cursor: pointer !important;
  border-radius: 32px !important;
  padding: 2px 12px !important;
  background-color: rgb(243, 228, 255) !important;
  color: rgb(147, 14, 255) !important;
  font-weight: 600 !important;
}

@media (max-width: 800px) {
  .portfolio {
    .chart {
      padding: 60px !important;
    }
    .ag-theme-alpine .ag-root-wrapper {
      border-bottom-right-radius: 13px;
    }
    .NQ-card-body {
      min-height: 419px !important;
    }
  }
}
@media (min-width: 800px) {
  .portfolio {
    .chart {
      padding: 60px;
      padding-bottom: 10px !important;
      padding-top: 10px !important;
      .ag-header-cell-label {
        align-self: center !important;
        justify-content: center !important;
      }
    }
    .ag-header-cell-label {
      align-self: center !important;
      justify-content: center !important;
    }
  }
}

.title {
  margin: 0px;
  font-style: normal;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif;
  font-weight: 700 !important;
  color: rgb(18, 0, 59) !important;
}

.cardHeading {
  margin: 0px;
  font-style: normal;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: rgb(18, 0, 59) !important;
  font-weight: 600 !important;
}

svg {
  path {
    stroke-width: 0px !important;
  }
}

.viewAll {
  font-size: 12px;
  color: rgb(96, 12, 232);
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif;
  font-weight: 600;
}
.newsTitle {
  font-family: "Playfair Display", serif;
  color: rgb(18, 0, 59);
  font-weight: 700;
  margin: 0px;
}
.newsSubTitle {
  margin: 0px;
  font-weight: normal;
  font-style: normal;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: rgb(111, 112, 140);
}

.newsCardShadow {
  box-sizing: border-box;
  border-radius: 16px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(18 0 59 / 12%) 0px 2px 6px, rgb(18 0 59 / 4%) 0px -1px 5px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

li:hover {
  color: rgb(96, 12, 232);
  text-decoration: none;
}

.title {
  margin: 0px !important;
  font-style: normal !important;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif !important;
  font-weight: 700 !important;
  color: #12003b !important;
  font-size: 30px !important;
}

h6 {
  margin: 0px !important;
  font-style: normal !important;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 26px !important;
  color: #12003b !important;
}

.numberValue {
  margin: 0px !important;
  font-style: normal !important;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif !important;
  font-size: 14px !important;
  line-height: 22px !important;
  text-align: left !important;
  color: #12003b !important;
  font-weight: 600 !important;
}
