.content {
  max-width: 500px;
  margin: auto;
  background: white;
  padding: 10px;
}

@media (max-width: 960px) {
  .Request {
    margin-left: 5% !important;
    margin-right: 5% !important;
  }
}
.Request {
  // div {
  //   display: block;
  // }
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 5%;

  .title {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .action-area {
    display: flex;
    justify-content: space-between;
    // margin-right: 23px;
    margin-top: 10px;
    margin-bottom: 10px;
    .title {
      font-size: 20px;
    }
  }

  .NQ-card-body {
    display: block !important;
  }
  .download-Icon {
    svg {
      height: 18px !important;
      margin-top: 8px;
    }
  }
  .activeStatus {
    // background: green;
    // padding: 0px 19px 1px 0px;
    // border-radius: 0;
    // border: 1px solid green;
    // border-radius: 15px;
    svg {
      margin-top: 13px !important;
      fill: green;
    }
  }
  .pendingStatus {
    // background: green;
    // padding: 0px 19px 1px 0px;
    // border-radius: 0;
    // border: 1px solid green;
    // border-radius: 15px;
    svg {
      margin-top: 13px !important;
      fill: rgb(255, 238, 0);
    }
  }
  .notactiveStatus {
    svg {
      margin-top: 13px !important;
      fill: rgb(247, 3, 3);
    }
  }
  .NQ-web .NQ-card .NQ-card-body{
    padding: 0px !important;
  }
  .ag-theme-alpine .ag-root-wrapper{
    border-radius: 15px !important;
  
  }
}

@media (max-width: 960px) {
  .Request {
    .NQ-card-body {
      display: block !important;
    }
    .Requesttext {
      font-size: 15px !important;
    }
    .Requestcategory {
      font-size: 13px !important;
    }
    .download-Icon {
      float: right;
      svg {
        height: 18px !important;
      }
    }
    .activeStatus {
      float: right;
      svg {
        margin-top: 0px !important;
        fill: green;
      }
    }
    .pendingStatus {
      float: right;
      svg {
        margin-top: 0px !important;
        fill: rgb(255, 238, 0);
      }
    }
    .notactiveStatus {
      float: right;
      svg {
        margin-top: 0px !important;
        fill: rgb(247, 3, 3);
      }
    }
    .NQ-web .NQ-card .NQ-card-body{
      padding: 0px !important;
    }
    .ag-theme-alpine .ag-root-wrapper{
      border-radius: 15px !important;
    
    }
  }
}



@media (min-width:961px){
  .Request{

    th:nth-child(1){
      border-top-left-radius: 15px;
      width: 60px;
    }
    th:nth-last-child(1){
     border-top-right-radius: 15px;
    }
  }
}
@media (max-width:960px){

}
.Request{
  .action-Icon{
    svg{
      height: 16px !important;
    }
  }
}

.unselectable{
  pointer-events: none;
}