@media (max-width: 959px) {
  .Contribution {
    margin-left: 5% !important;
    margin-right: 5% !important;

    .css-sp7t83 {
      display: block !important;
      .css-iz72e7 {
        display: block !important;
        width: 100% !important;
        margin-bottom: 10px !important;
      }
      .css-1gxohh2 {
        display: block !important;
        width: 100% !important;
      }
    }
    .p-15 {
      padding: 0 1% !important;
    }
  }
}
@media (min-width: 960px) {
  .Contribution {
    .p-15 {
      padding: 0 25% 0 0 !important;
    }
  }
}
.Contribution {
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 5% !important;
  body {
    --webkit-font-smoothing: antialiased;
    background-color: rgb(245, 245, 248);
  }
  .css-sp7t83 {
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
    margin-top: 24px;
  }
  .css-iz72e7 {
    display: flex;
    box-sizing: border-box;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: fit-content;
    font-weight: 600;
    border-width: 3px;
    border-style: solid;
    border-color: transparent;
    cursor: pointer;
    line-height: 22px;
    transition-duration: 150ms;
    height: 32px;
    min-width: 96px;
    font-size: 14px;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 16px;
    color: rgb(96, 12, 232);
    background-color: rgb(245, 245, 248);
    margin-right: 12px;
  }
  .css-p {
    margin: 0px;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: black;
  }
  .css-text {
    color: black;
  }
  .css-1gxohh2 {
    display: flex;
    box-sizing: border-box;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: fit-content;
    font-weight: 600;
    border-width: 3px;
    border-style: solid;
    cursor: pointer;
    line-height: 22px;
    transition-duration: 150ms;
    height: 32px;
    min-width: 96px;
    font-size: 14px;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 16px;
    color: rgb(96, 12, 232);
    background-color: transparent;
    border-color: rgb(96, 12, 232);
  }
  .css-1qm12h {
    margin: 0px;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: black;
  }
  .css-ltsw9u {
    display: flex;
    flex-flow: row wrap;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 1152px;
    padding-left: 12px;
    padding-right: 12px;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    align-items: stretch;
  }
  .css-naxvie {
    box-sizing: border-box;
    // padding-left: 12px;
    // padding-right: 12px;
    width: 100%;
    display: block;
  }
  .css-zaghsn {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-top: 24px;
    // margin-bottom: 24px;
  }
  h1 {
    margin: 0px;
    font-size: 28px;
    font-weight: 700;
    line-height: 42px;
  }
  .css-18ee882 {
    display: flex;
    box-sizing: border-box;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: fit-content;
    font-weight: 600;
    border-width: 3px;
    border-style: solid;
    border-color: transparent;
    cursor: pointer;
    line-height: 22px;
    transition-duration: 150ms;
    height: 32px;
    min-width: 96px;
    font-size: 14px;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 16px;
    color: rgb(255, 255, 255);
    background-color: rgb(96, 12, 232);
  }

  .css-1qbatn3 {
    box-sizing: border-box;
    padding-left: 12px;
    padding-right: 12px;
    width: 50%;
    display: block;
  }

  .css-1ulh703 {
    margin-bottom: 48px;
  }

  .css-18akwle {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 12px;
  }
  .css-vy2pbh {
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    box-shadow: rgb(18 0 59 / 12%) 0px 2px 6px, rgb(18 0 59 / 4%) 0px -1px 5px;
    border-radius: 16px;
  }
  .css-12u19mu {
    position: absolute;
    top: 12px;
    right: 12px;
  }
  .css-3or2ho {
    height: 24px;
    max-width: max-content;
    display: flex;
    padding: 0px 12px;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    border-radius: 32px;
    color: rgb(0, 170, 160);
    background-color: rgb(201, 254, 239);
  }
  .css-xqrdoy {
    padding: 32px;
  }
  .css-1qm1lh {
    margin-bottom: 16px;
  }
  .css-fqk8rb {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    padding-bottom: 4px;
    color: rgb(111, 112, 140);
  }
  .css-fqk8rb {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    padding-bottom: 4px;
    color: rgb(111, 112, 140);
  }
  .css-1crpfoq {
    margin: 0px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    color: rgb(0, 0, 0);
  }
  .css-1ulh703 {
    margin-bottom: 48px;
  }
  .css-18akwle {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 12px;
  }
  .css-1rpx5uk {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: rgb(96, 12, 232);
  }
  .css-vy2pbh {
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    box-shadow: rgb(18 0 59 / 12%) 0px 2px 6px, rgb(18 0 59 / 4%) 0px -1px 5px;
    border-radius: 16px;
  }
  .css-xqrdoy {
    padding: 32px;
  }
  .css-1qm1lh {
    margin-bottom: 16px;
  }
  .css-fqk8rb {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    padding-bottom: 4px;
    color: rgb(111, 112, 140);
  }
  .css-1qm1lh {
    margin-bottom: 16px;
  }
  .css-fqk8rb {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    padding-bottom: 4px;
    color: rgb(111, 112, 140);
  }
  .css-1crpfoq {
    margin: 0px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    color: rgb(0, 0, 0);
  }
  .css-fqk8rb {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    padding-bottom: 4px;
    color: rgb(111, 112, 140);
  }
  .css-1crpfoq {
    margin: 0px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    color: rgb(0, 0, 0);
  }
  .css-1ulh703 {
    margin-bottom: 48px;
  }
  .css-18akwle {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 12px;
  }
  .css-1rpx5uk {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: rgb(96, 12, 232);
  }

  .css-vy2pbh {
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    box-shadow: rgb(18 0 59 / 12%) 0px 2px 6px, rgb(18 0 59 / 4%) 0px -1px 5px;
    border-radius: 16px;
  }

  .css-xqrdoy {
    padding: 32px;
  }

  .css-1b7waib {
    margin: 0px 0px 16px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: rgb(111, 112, 140);
    text-transform: uppercase;
  }
  p {
    margin: 0px;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
  }
  p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  .css-1081t4c {
    text-decoration: underline;
  }
  .css-1ut6rcl {
    margin: 0px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: black;
  }
  .css-1ulh703 {
    margin-bottom: 48px;
  }
  .css-18akwle {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 12px;
  }
  .css-vy2pbh {
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    box-shadow: rgb(18 0 59 / 12%) 1px 4px 24px, rgb(18 0 59 / 8%) 0px 4px 8px;
    border-radius: 16px;
  }
  .css-xqrdoy {
    padding: 32px;
  }
  .css-1qbatn3 {
    box-sizing: border-box;
    padding-left: 12px;
    padding-right: 12px;
    width: 50%;
    display: block;
  }
  .css-1ulh703 {
    margin-bottom: 48px;
  }
  .css-18akwle {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 12px;
  }
  .css-phtnxg {
    transition-duration: 150ms;
    color: rgb(96, 12, 232);
    font-weight: 600;
    font-size: 12px;
  }
  .css-vy2pbh {
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    box-shadow: rgb(18 0 59 / 12%) 0px 2px 6px, rgb(18 0 59 / 4%) 0px -1px 5px;
    border-radius: 16px;
  }
  .css-xqrdoy {
    padding: 32px;
  }

  .css-1wj0762 {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-end;
  }
  .css-fqk8rb {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    padding-bottom: 4px;
    color: rgb(111, 112, 140);
  }
  .css-1crpfoq {
    margin: 0px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    color: rgb(0, 0, 0);
  }
  .css-1rpx5uk {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: rgb(96, 12, 232);
  }
  .css-1ulh703 {
    margin-bottom: 48px;
  }
  .css-18akwle {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 12px;
  }
  .css-phtnxg {
    transition-duration: 150ms;
    color: rgb(96, 12, 232);
    font-weight: 600;
    font-size: 12px;
  }
  .css-vy2pbh {
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    box-shadow: rgb(18 0 59 / 12%) 0px 2px 6px, rgb(18 0 59 / 4%) 0px -1px 5px;
    border-radius: 16px;
  }
  .css-12u19mu {
    position: absolute;
    top: 12px;
    right: 12px;
  }
  .css-3or2ho {
    height: 24px;
    max-width: max-content;
    display: flex;
    padding: 0px 12px;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    border-radius: 32px;
    color: rgb(0, 170, 160);
    background-color: rgb(201, 254, 239);
  }
  .css-xqrdoy {
    padding: 32px;
  }
  .css-1oimov7 {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 12px;
    position: relative;
  }
  .css-1vg6q84 {
    font-weight: 700;
    color: black;
  }
  .css-1wj0762 {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-end;
  }
  .css-fqk8rb {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    padding-bottom: 4px;
    color: rgb(111, 112, 140);
  }
  .css-1crpfoq {
    margin: 0px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    color: rgb(0, 0, 0);
  }
  .css-fqk8rb {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    padding-bottom: 4px;
    color: rgb(111, 112, 140);
  }
  .css-1crpfoq {
    margin: 0px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    color: rgb(0, 0, 0);
  }
  .css-s2uf1z {
    text-align: right;
  }
  .css-fqk8rb {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    padding-bottom: 4px;
    color: rgb(111, 112, 140);
  }
  .css-1crpfoq {
    margin: 0px;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
    color: rgb(0, 0, 0);
  }
  .css-1ulh703 {
    margin-bottom: 48px;
  }
  .css-18akwle {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 12px;
  }
  .css-phtnxg {
    transition-duration: 150ms;
    color: rgb(96, 12, 232);
    font-weight: 600;
    font-size: 12px;
  }
  .css-vy2pbh {
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    box-shadow: rgb(18 0 59 / 12%) 0px 2px 6px, rgb(18 0 59 / 4%) 0px -1px 5px;
    border-radius: 16px;
  }
  .css-1gvwazu {
    border-left: 4px solid transparent;
    overflow: hidden;
    padding: 24px 32px;
    transition-duration: 150ms;
  }
  .css-9jay18 {
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .css-165znzt {
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    flex-grow: 1;
  }
  .css-1jqvzpx {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: rgb(111, 112, 140);
    min-width: 90px;
  }
  .css-1vseuxq {
    margin: 0px 16px 0px 0px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: rgb(0, 0, 0);
    -webkit-box-flex: 1;
    flex-grow: 1;
  }
  .css-18wwfoy {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: rgb(111, 112, 140);
    min-width: 100px;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
  }
  .css-1l0z8uk {
    flex-direction: row;
  }
  .css-nb6ul0 {
    margin-right: 4px;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
  }
  .css-1qvwzoe {
    border-color: rgb(111, 112, 140);
    border-width: 1px;
    border-radius: 7px;
    height: 7px;
    width: 7px;
    background-color: rgb(111, 112, 140);
  }
  .css-1e8abgn {
    width: 4px;
    height: 1px;
    background-color: rgb(111, 112, 140);
  }
  .css-1s6qyqw {
    border-color: rgb(111, 112, 140);
    border-width: 1px;
    border-radius: 7px;
    height: 7px;
    width: 7px;
    background-color: rgb(227, 225, 232);
  }
  .css-1s9eamm {
    width: 4px;
    height: 1px;
    background-color: rgb(227, 225, 232);
  }
  .css-1s6qyqw {
    border-color: rgb(111, 112, 140);
    border-width: 1px;
    border-radius: 7px;
    height: 7px;
    width: 7px;
    background-color: rgb(227, 225, 232);
  }
  .css-18wwfoy span {
    padding-left: 4px;
  }
  .css-1kecnp1 {
    margin: 0px 16px 0px 0px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: rgb(0, 0, 0);
    min-width: 80px;
    text-align: right;
    align-self: center;
  }
  .css-oj9lgu {
    max-height: 0px;
    overflow: hidden;
    transition-duration: 150ms;
  }
  .css-1bep9n9 {
    border-top: 1px solid rgb(227, 225, 232);
    margin-left: 90px;
    margin-top: 24px;
    padding-top: 24px;
  }
  .css-1gvwazu:not(:first-child) {
    border-top: 1px solid rgb(239, 238, 242);
  }
  .css-1gvwazu {
    border-left: 4px solid transparent;
    overflow: hidden;
    padding: 24px 32px;
    transition-duration: 150ms;
  }
  .css-9jay18 {
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .css-165znzt {
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    flex-grow: 1;
  }
  .css-1jqvzpx {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: rgb(111, 112, 140);
    min-width: 90px;
  }
  .css-1vseuxq {
    margin: 0px 16px 0px 0px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: rgb(0, 0, 0);
    -webkit-box-flex: 1;
    flex-grow: 1;
  }
  .css-18wwfoy {
    margin: 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: rgb(111, 112, 140);
    min-width: 100px;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
  }
  .css-1l0z8uk {
    flex-direction: row;
  }
  .css-nb6ul0 {
    margin-right: 4px;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
  }
  .css-19jkesh {
    border-color: rgb(111, 112, 140);
    border-width: 1px;
    border-radius: 7px;
    height: 7px;
    width: 7px;
    background-color: rgb(8, 210, 178);
  }
  .css-1rxuejg {
    width: 4px;
    height: 1px;
    background-color: rgb(8, 210, 178);
  }
  .css-19jkesh {
    border-color: rgb(111, 112, 140);
    border-width: 1px;
    border-radius: 7px;
    height: 7px;
    width: 7px;
    background-color: rgb(8, 210, 178);
  }
  .css-1rxuejg {
    width: 4px;
    height: 1px;
    background-color: rgb(8, 210, 178);
  }
  .css-19jkesh {
    border-color: rgb(111, 112, 140);
    border-width: 1px;
    border-radius: 7px;
    height: 7px;
    width: 7px;
    background-color: rgb(8, 210, 178);
  }
  .css-1kecnp1 {
    margin: 0px 16px 0px 0px;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: rgb(0, 0, 0);
    min-width: 80px;
    text-align: right;
    align-self: center;
  }
  .css-oj9lgu {
    max-height: 0px;
    overflow: hidden;
    transition-duration: 150ms;
  }
  .css-1bep9n9 {
    border-top: 1px solid rgb(227, 225, 232);
    margin-left: 90px;
    margin-top: 24px;
    padding-top: 24px;
  }
  .css-1bep9n9 {
    border-top: 1px solid rgb(227, 225, 232);
    margin-left: 90px;
    margin-top: 24px;
    padding-top: 24px;
  }
  .css-1u6w44c {
    padding: 32px;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    height: 120px;
  }
  .css-1vj6hdk {
    margin-top: 32px;
    width: 100%;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    display: none;
    color: rgb(111, 112, 140);
    font-size: 14px;
  }
  .css-1iuj5ih {
    padding-left: 8px;
  }

  .MuiTab-textColorInherit {
    background-color: rgb(227, 225, 232) !important;
    color: rgb(96, 12, 232) !important;
    border: none !important;
    border-radius: 10px !important;
    margin-bottom: 10px;
    min-width: 200px !important;
  }
  .PrivateTabIndicator-colorSecondary-35 {
    background-color: transparent !important;
  }
  .profile-tab {
    font-style: normal !important;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 26px !important;
    border-radius: 8px !important;
    padding: 16px 24px !important;
    display: flex !important;
    -webkit-box-align: center !important;
    align-items: center !important;
    transition: all 150ms linear 0s !important;
  
    color: rgb(111, 112, 140) !important;
    width: 100% !important;
    margin-bottom: 10px;
  }
  
  .active-tab {
    color: rgb(96, 12, 232) !important;
    background-color: rgb(227, 225, 232) !important;
  }
}

.title {
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
  margin-bottom: 10px;
  font-size: 20px;
}



.eFrFNd {
  margin: 0px;
  font-weight: normal;
  font-style: normal;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 16px;
  line-height: 26px;
  color: rgb(18, 0, 59);
  .css-1hgfhw8 {
    transition-duration: 150ms;
    color: rgb(96, 12, 232);
    font-weight: 600;
    text-decoration: none;
  }
}
.foxWyQ {
  padding: 32px !important;
  .cJAFac {
    margin-bottom: 24px;
    .gkRBAV {
      display: flex;
      flex-direction: column;
      .cdDuvV {
        margin-bottom: 16px;
        .eFrFNd {
          margin: 0px;
          font-weight: normal;
          font-style: normal;
          font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif;
          font-size: 16px;
          line-height: 26px;
          color: rgb(18, 0, 59);
        }
      }
      .jPDzqR {
        margin: 0px;
        font-style: normal;
        font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif;
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        color: rgb(18, 0, 59);
      }
      .hfzgIf {
        box-sizing: border-box;
        background-color: rgb(245, 245, 248);
        border-radius: 16px;
        padding: 24px;
        padding-bottom: 9px;
        .bouexZ {
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          -webkit-box-pack: justify;
          justify-content: space-between;
        }
      }
    }
  }
}
.hbVuhF {
  vertical-align: middle;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.XqjMf {
  vertical-align: middle;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
