.content {
  max-width: 500px;
  margin: auto;
  background: white;
  padding: 10px;
}

@media (max-width: 960px) {
  .Goal {
    margin-left: 5% !important;
    margin-right: 5% !important;
  }

  .NQ-right{
    margin-top: -40px;
  }
}
.Goal {
  // div {
  //   display: block;
  // }
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 5%;

  .title {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .action-area {
    display: flex;
    justify-content: space-between;
    // margin-right: 23px;
    margin-top: 10px;
    margin-bottom: 10px;
    .title {
      font-size: 20px;
    }
    .action {
      display: flex;
    }
  }

  .NQ-web .NQ-card .NQ-card-body {
    // padding: 4px 10px 0px 10px !important;
    .NQ-left {
      width: 60% !important;
      justify-content: flex-start !important;
      .NQ-col-1 {
        display: flex;
        align-self: center;
      }
      .NQ-col-2 {
        display: flex;
        align-items: center;
        margin-left: 10px;
        .text-wrap {
        }
      }
      .goaltext {
        font-size: 20px;
        font-weight: 700;
        white-space: nowrap;
        line-height: 1;
      }
      .goalcategory {
        font-size: 15px;
        line-height: 1;
        white-space: nowrap;
      }
    }
  }
  .NQ-web .NQ-card .NQ-card-body .NQ-right {
    width: 100% !important;
    justify-content: space-between !important;
    .NQ-col-1 {
      align-self: center;
    }
  }
}

@media (max-width: 600px) {
  .Goal {
    .NQ-card-body {
      display: block !important;
    }
    .goaltext {
      font-size: 15px !important;
      white-space: nowrap;
      line-height: 1;
    }
    .goalcategory {
      font-size: 13px !important;
      line-height: 1;
      white-space: nowrap;
    }
    .NQ-right{
      margin-top: -40px;
    }
  }
}


.NQ-card{
  box-shadow: rgb(18 0 59 / 12%) 0px 2px 6px, rgb(18 0 59 / 4%) 0px -1px 5px;
}


.goalammount{
  margin: 0px;
  font-style: normal;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif;
  text-align: left;
  color: rgb(0, 0, 0);
}
.goaltext{
  margin: 0px;
  font-style: normal;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: rgb(18, 0, 59);
  font-weight: 600;
}

.goalcategory{
    white-space: nowrap;
    margin: 0px;
    font-style: normal;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif;
    line-height: 18px;
    color: rgb(111, 112, 140);
    font-weight: 600;
}