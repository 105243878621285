.loadingchartandtable
{
    position: relative;
    display: flex;
    justify-content: center;
    height: 50vh;
}
.notfound
{
    height: 50vh;
}