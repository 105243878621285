@media (max-width: 960px) {
  .Pots {
    margin-left: 0 !important;
    margin-right: 0 !important;
    .title {
      padding-left: 20px;
    }
  }
}

.Pots {
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 5%;

  svg rect {
     fill: #ffffff00 !important; 
  }
  

  .title {
    // font-size: 20px;
    // margin-bottom: 10px;
    margin: 0px !important;
    font-style: normal !important;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif !important;
    font-weight: 700 !important;
    color: #12003b !important;
    font-size: 30px !important;
    padding-left: 17px !important;
  }

  .mb-10 {
    margin-bottom: 10px;
  }
  .action-area {
    display: flex;
    justify-content: space-between;
    // margin-right: 23px;
    margin-top: 10px;
    margin-bottom: 10px;
    .title {
      font-size: 20px;
    }
    .action {
      display: flex;
    }
  }
  .w-100 {
    width: 100%;
  }
}

//text field

//text field
