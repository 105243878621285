/*! CSS Used from: https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css */
.Potdetaillist {
  margin-left: 20%;
  margin-right: 20%;
  .css-1kmimlc {
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    box-shadow: rgb(18 0 59 / 12%) 1px 4px 24px, rgb(18 0 59 / 8%) 0px 4px 8px;
    border-radius: 16px;
    margin-bottom: 24px;
  }
  .css-4lc1dm {
    border-width: 0px 0px 1px;
    border-image: initial;
    border-style: solid;
    border-color: rgb(227, 225, 232);
    padding: 16px 32px;
  }
  .css-4lc1dm h3 {
    margin: 0px;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    color: black;
  }

  .NQ-Chart {
    height: 500px !important;
    display: flex !important;
    justify-content: center !important;
  }
  .NQ-Chart > div {
    align-self: center !important;
  }
}
@media (max-width: 960px) {
  .Potdetaillist {
    margin-left: 5% !important;
    margin-right: 5% !important;
  }
}
.ag-body-viewport-wrapper.ag-layout-normal {
  overflow-x: scroll;
  overflow-y: scroll;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #250f5a !important; ///rgba(0,0,0,.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

@media (max-width: 960px) {
  .Potdetail {
    margin-left: 5% !important;
    margin-right: 5% !important;
    .Homecard .force-overflow {
      min-height: 100px !important;
    }
    .title {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }
}

.Potdetail {
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 5%;
  .Homecard .force-overflow {
    min-height: 100px !important;
  }
  .title {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .action-area {
    display: block;
  }
}

.addpot {
  float: right !important;
}




@media (min-width:1081px){
  .Potdetaillist{

    // th:nth-child(1){ 
    //   width: 5vw !important;
    // }
    // th:nth-child(2){ 
    //   width: 45vw !important;
    // }
    // th:nth-child(3){ 
    //   width: 40vw !important;
    // }
    // th:nth-last-child(1){ 
    //   width: 10vw !important;
    // }
    td:nth-child(1){ 
      width: 5vw !important;
    }
    td:nth-child(2){ 
      width: 45vw !important;
    }
    td:nth-child(3){ 
      width: 40vw !important;
    }
    td:nth-last-child(1){ 
      width: 10vw !important;
    }

    .css-yk16xz-control{
      box-shadow: none !important;
      border: none !important;
    border-bottom: 1px solid hsl(0, 0%, 80%) !important;
    border-radius: 0px !important;
    }
    .w-100{
      width: calc(100% - 2%);
      z-index: 9;
      position: relative;
    }
    #no-more-tables{

      #myTable{
        border-collapse: collapse !important;
        .header:hover {
          background: transparent !important;
          border: none !important;
        }
        tr:hover {
          background-color: #f2f2f282 !important;
          border-bottom: 2px solid #600ce8 !important;
          cursor: pointer !important;
        }
        tr:focus {
          background-color: #96c2ff38 !important;
          border-bottom: 2px solid #600ce8 !important;
          cursor: pointer !important;
        }
        thead{
          th{
            background-color: #fff !important;
            border:1px solid #ddd !important;
          }
        }
        tbody{
        
          td:nth-child(1){
            text-align: center !important;
          }
          td{
            padding:  0 !important;
            background-color: transparent !important;
            border:1px solid #ddd !important;
            padding-left: 10px !important;
            padding-right: 10px !important;
            .grid-input{
              padding: 10px !important;
              border: none !important;
              background: transparent !important;
            }
             .Mui-focused{

             }
             .MuiInput-underline{
               input{
                 padding-left: 10px !important;
               }
             }
             .MuiInput-underline:before{
              border-bottom: none !important;
              transition:none !important
             }
             .MuiInput-underline:after{
               border-bottom: none !important;
               transition:none !important
             }
            input.grid-input:focus {
              background: #fff !important;
              border: 1px solid #ddd !important;
          }
          .css-yk16xz-control{
            background-color: transparent !important;
            border-bottom: none !important;
          }

          }

         
        }
      }
      .action-Icon{
        .delete{
          svg{
            height: 23px !important; 
            fill: #333 !important;
          }
        
        }
        .clear{
          svg{
            height: 23px !important; 
            fill: #333 !important;
          }
        
        }
        
        .done{
         svg{
          height: 23px !important; 
          fill: #333 !important;
         }
        }
        }
    }
  }
}
@media (max-width:1080px){
  .Potdetaillist{
    // .action-Icon{
    //   svg{
    //     height: 16px !important;
    //     float: right !important;
    //   }
    // }

    .action-Icon{
      .delete{
        svg{
          height: 16px !important; 
          fill: #333 !important;
        }
      
      }
      .clear{
        svg{
          height: 23px !important; 
          fill: #333 !important;
        }
      
      }
      
      .done{
       svg{
        height: 23px !important; 
        fill: #333 !important;
       }
      }
      }
    .w-100{
      width: 100%;
    }
    .grid-input{
      width: calc(100% - 10%);
    }
    .css-yk16xz-control{
      box-shadow : 0 0 0 1px #333 !important
    }

  }
}


@media screen and (min-width: 800px) and  (min-device-width: 1070px){
  .Potdetaillist{
  #no-more-tables
  {
    table{
      border-collapse: collapse;
      th {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
        background: #fff !important;
        border: none !important;
        margin: 0px !important;
        border: none !important;
        text-align: left;
        padding-left: 10px !important;
        padding-right: 10px !important;
        border: 1px solid #ddd !important;
      }
      td {
        border: 1px solid #ddd !important;
      }
      td{
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
      }

      tbody{
       
      }
  }
}
}

.Potdetaillist{
  // .action-Icon{
  //   svg{
  //     height: 16px !important;
  //   }
  // }

}


.grid-input{
  padding: 10px; 
  border: none;
  border-bottom: 1.5px solid hsl(0, 0%, 80%);
}



input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}



.margin-l-r-10{
  margin-left: 10px !important;
  margin-right: 10px !important;
}