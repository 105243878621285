@media (max-width: 960px) {
  //mobile view
  .addgoalhome {
    margin-left: 5% !important;
    margin-right: 5% !important;
    .makeStyles-grid-40{
      padding: 0 !important;



  }
    .MuiTypography-body1 {
      .MuiGrid-container {
        display: block;
      }
    }
    .mobileview {
      margin: 0 3% !important;
    }
    .css-q4good {
      box-sizing: border-box;

      display: block;
      // margin-left: 15%;
      // margin-right: 15%;
      .css-1tu684v {
        text-align: center;
        margin-bottom: 24px;
        margin-top: 15px;
        font-size: 18px !important;
      }
      h1 {
        margin: 0;
        font-size: 24px;
        font-weight: 600;
        line-height: 42px;
      }
    }
    .css-1yjvs5a {
      // display: flex;
      // justify-content: center;
    }
    .note {
      text-align: center;
      margin-bottom: 10px;
    }
    .Assumptions {
      .NQ-Input-Text {
        input {
          width: auto !important;
        }
      }
    }
    .NQ-Input-Text {
      .css-79elbk {
        .css-npjedo {
          // margin: 0 40px !important;
          width: auto !important;
        }
      }
    }
    .MuiAccordion-root {
      // margin: 5px 60px !important;
      width: auto !important;
    }
    .css-1ucoqeb {
      background-color: #efeef2;
      margin-bottom: 5%;
      border-radius: 8px;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 24px;
      // margin: 3% 15%;
      // margin: 3% 4%;
      .sc-hhIhEF {
        text-align: center;
      }
      mat-label {
        text-align: center;
      }
    }
    .css-dvxtzn {
      display: flex;
      flex-direction: column;
      -webkit-box-align: center;
      align-items: center;
      .css-jhd49u {
        display: flex;
        box-sizing: border-box;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        width: fit-content;
        font-weight: 600;
        border-width: 3px;
        border-style: solid;
        border-color: transparent;
        cursor: pointer;
        line-height: 22px;
        transition-duration: 150ms;
        height: 56px;
        min-width: 220px;
        font-size: 16px;
        padding-left: 32px;
        padding-right: 32px;
        border-radius: 32px;
        color: rgb(255, 255, 255);
        background-color: rgb(96, 12, 232);
        margin-bottom: 12px;
      }
      .css-jhd49u:focus {
        border-color: rgb(219, 175, 254);
        outline: none;
      }
      .css-jhd49u:disabled {
        cursor: not-allowed;
      }
      .css-jhd49u:focus,
      .css-jhd49u:hover,
      .css-jhd49u:active {
        background-color: rgb(65, 16, 183);
      }
      .css-jhd49u:disabled {
        color: rgba(255, 255, 255, 0.4);
        background-color: rgba(96, 12, 232, 0.4);
      }
      .css-1gld5x9 {
        display: flex;
        box-sizing: border-box;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        width: fit-content;
        font-weight: 600;
        border-width: 3px;
        border-style: solid;
        border-color: transparent;
        cursor: pointer;
        line-height: 22px;
        transition-duration: 150ms;
        height: 56px;
        min-width: 220px;
        font-size: 16px;
        padding-left: 32px;
        padding-right: 32px;
        border-radius: 32px;
        color: rgb(96, 12, 232);
        background-color: rgb(245, 245, 248);
      }
      .css-1gld5x9:focus {
        border-color: rgb(219, 175, 254);
        outline: none;
      }
      .css-1gld5x9:disabled {
        cursor: not-allowed;
      }
      .css-1gld5x9:focus,
      .css-1gld5x9:hover,
      .css-1gld5x9:active {
        background-color: rgb(96, 12, 232);
        color: rgb(255, 255, 255);
      }
      .css-1gld5x9:disabled {
        color: rgba(96, 12, 232, 0.4);
      }
    }
    .MuiAccordionDetails-root {
      .NQ-Input-Text {
        .css-79elbk {
          .css-npjedo {
            margin: 0px !important;
            width: auto !important;
          }
        }
      }
    }
    .css-label {
      color: #000;
    }

    .next {
      margin: 0 3% !important;
      .sc-oefIU {
        // display: grid;
        // justify-content: center;
      }
      .text-center {
        text-align: center;
      }
      .sc-hhIhEF {
        .css-1ucoqeb {
          // margin: 2% 15% !important;
        }
      }
      .MuiSlider-colorPrimary {
        width: 80% !important;
        margin: 0 10% !important;
      }
    }

    .claculation-toogle{
      .MuiToggleButton-label{
        font-size: 11px;
        font-weight: 600;
      }
      .claculation{ 
        padding-right: 0px !important;
        display: block !important;
      }
      .border-r-1{
        border-right: 1px solid #ddd !important;
      }
      .claculation-ammount{
        text-align: left;
        padding-right: 15px ;
        font-weight: 600;
      }
    } 
  }
  .makeStyles-grid-36 {
    padding: 0 !important;
  }

}
@media (min-width: 960px) {
  //web view
  .addgoalhome {
    margin-left: 15%;
    margin-right: 15%;
    .makeStyles-grid-40 {
      padding: 0 !important;
    }
    .MuiTypography-body1 {
      .MuiGrid-container {
        display: block;
      }
    }
    .css-q4good {
      box-sizing: border-box;
      padding-left: 12px;
      padding-right: 12px;
      display: block;
      // margin-left: 15%;
      // margin-right: 15%;
      .css-1tu684v {
        text-align: center;
        // margin-bottom: 24px;
        // margin-top: 64px;
      }
      h1 {
        margin: 0;
        font-size: 24px;
        font-weight: 600;
        line-height: 42px;
      }
    }
    .css-1yjvs5a {
      display: flex;
      justify-content: center;
    }
    .note {
      text-align: center;
      margin-bottom: 10px;
    }
    .NQ-Input-Text {
      .css-79elbk {
        .css-npjedo {
          // margin: 0 50px !important;
          width: auto !important;
        }
      }
    }
    .MuiAccordion-root {
      // margin: 5px 50px !important;
    }
    .css-1ucoqeb {
      background-color: #efeef2;
      margin-bottom: 5%;
      border-radius: 8px;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 24px;
      // margin: 3% 19%;
      // margin: 10px ;
      .sc-hhIhEF {
        text-align: center;
      }
      mat-label {
        text-align: center;
      }
    }
    .css-dvxtzn {
      display: flex;
      flex-direction: column;
      -webkit-box-align: center;
      align-items: center;
      .css-jhd49u {
        display: flex;
        box-sizing: border-box;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        width: fit-content;
        font-weight: 600;
        border-width: 3px;
        border-style: solid;
        border-color: transparent;
        cursor: pointer;
        line-height: 22px;
        transition-duration: 150ms;
        height: 56px;
        min-width: 220px;
        font-size: 16px;
        padding-left: 32px;
        padding-right: 32px;
        border-radius: 32px;
        color: rgb(255, 255, 255);
        background-color: rgb(96, 12, 232);
        margin-bottom: 12px;
      }
      .css-jhd49u:focus {
        border-color: rgb(219, 175, 254);
        outline: none;
      }
      .css-jhd49u:disabled {
        cursor: not-allowed;
      }
      .css-jhd49u:focus,
      .css-jhd49u:hover,
      .css-jhd49u:active {
        background-color: rgb(65, 16, 183);
      }
      .css-jhd49u:disabled {
        color: rgba(255, 255, 255, 0.4);
        background-color: rgba(96, 12, 232, 0.4);
      }
      .css-1gld5x9 {
        display: flex;
        box-sizing: border-box;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        width: fit-content;
        font-weight: 600;
        border-width: 3px;
        border-style: solid;
        border-color: transparent;
        cursor: pointer;
        line-height: 22px;
        transition-duration: 150ms;
        height: 56px;
        min-width: 220px;
        font-size: 16px;
        padding-left: 32px;
        padding-right: 32px;
        border-radius: 32px;
        color: rgb(96, 12, 232);
        background-color: rgb(245, 245, 248);
      }
      .css-1gld5x9:focus {
        border-color: rgb(219, 175, 254);
        outline: none;
      }
      .css-1gld5x9:disabled {
        cursor: not-allowed;
      }
      .css-1gld5x9:focus,
      .css-1gld5x9:hover,
      .css-1gld5x9:active {
        background-color: rgb(96, 12, 232);
        color: rgb(255, 255, 255);
      }
      .css-1gld5x9:disabled {
        color: rgba(96, 12, 232, 0.4);
      }
    }
    .MuiAccordionDetails-root {
      .NQ-Input-Text {
        .css-79elbk {
          .css-npjedo {
            margin: 0px !important;
            width: auto !important;
          }
        }
      }
    }
    .css-label {
      color: #000;
    }

    .next {
      // margin: 0 20% !important;
      .cAmEDQ {
        // display: grid;
        // justify-content: center;
        // margin:  0% 25% !important;
      }
      .text-center {
        text-align: center;
      }
      .sc-hhIhEF {
        .sc-oefIU {
          // padding: 0 45px !important;
        }
        .css-1ucoqeb {
          // margin: 2% 25% !important;
        }
      }
      .MuiSlider-colorPrimary {
        width: 80% !important;
        margin: 0 10% !important;
      }
    }
 
    .claculation-toogle{
      .MuiToggleButtonGroup-root{
        display: flex !important;
        justify-content: center !important;
      }
      .claculation{ 
        padding-right: 0px !important;
        display: block !important;
      }
      .border-r-1{
        border-right: 1px solid #ddd !important;
      }
      .claculation-ammount{
        text-align: left;
        padding-right: 15px ;
        font-weight: 600;
      }
    } 
  }

  //5/20/2021
  .firststep {
    margin: 0px 32% !important;
    .css-1ucoqeb {
      margin: 2% 3% !important;
    }
  }
  .nextstep {
    margin: 0px 33% !important;
    .cAmEDQ {
      // margin: 0% 15% !important;
    }
    .ecNPTU {
      // margin: 0% 15% !important;
    }
  }
  //5/20/2021
}


.Mui-selected{
  border: 2px solid #4110b7 !important;
  background-color: #fff !important;
}


.MuiToggleButtonGroup-grouped {

}


.MuiToggleButtonGroup-groupedHorizontal:nth-child(1){
  border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
}
.MuiToggleButtonGroup-groupedHorizontal:nth-child(1) span{
 padding:  0 20px !important;
}
.MuiToggleButtonGroup-groupedHorizontal:nth-child(2){
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.MuiToggleButtonGroup-groupedHorizontal:nth-child(2) span{
  padding:  0 10px !important;
 }

//  .NQ-Input-Text {
//   margin-top: 10px;
// }

.m-b-10{
  margin-bottom: 10px !important;
}
.m-t-0{
  margin-top: 0px !important;
}
.m-b-0{
  margin-bottom: 0px !important;
}
