.unauthorized {
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #F5F5F8;
    div {
        align-self: center;
        .nomargin {
            margin: 0px !important;
        }
    }
}
.errorcode{
    font-size: 210px;
    font-weight: 900;
    line-height: 210px;
    margin: 0px;
    text-shadow: 4px 5px 1px #b3bbbf;
}
