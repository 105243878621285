@media (min-width: 960px) {
  .Portfoliolist {
    padding-left: 5%;
    padding-right: 7%;
  }
}
.pretag {
  width: 85%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Portfoliolist {
  .action-area {
    display: flex;
    justify-content: space-between;
    // margin-right: 23px;
    margin-top: 10px;
    margin-bottom: 10px;
    .title {
      font-size: 20px;
    }
    .action {
      display: flex;
    }
  }
  .w-100 {
    width: 100%;
  }

  @media (max-width: 700px) {
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    .vueNow-calculation-box {
      display: inline-table;
      /* font-size: 9px; */
      width: 100%;
      height: 92px !important;
      .lable {
        font-size: 9px !important;
      }
      .count {
        font-size: 7px !important;
      }
    }
    .action-area {
      margin-right: 0 !important;
      .title {
        font-size: 11px !important;
      }
    }
  }
}

@media (max-width: 960px) {
  .Portfoliolist {
    margin-left: 5%;
    margin-right: 5%;
    .action-area > div {
      text-align: center !important;
      margin-bottom: 10px;
    }
    .action-area {
      display: block !important;

      .title {
        font-size: 15px !important;
      }
    }
    .NQ-btn-blue {
      font-size: 10px !important;
    }
    h6 {
      font-size: 13px !important;
      font-weight: 600 !important;
    }
    .text-right {
      font-size: 13px !important;
      font-weight: 600 !important;
    }
    .NQ-card-button {
      min-width: 60px !important;
    }
    .NQ-card-body > .NQ-row-1 > .NQ-col-1 {
      width: 60% !important;
    }
    .Non-Cash {
      font-size: 13px !important;
    }
    .Cash {
      font-size: 13px !important;
    }
    .moreLink {
      font-size: 13px !important;
    }
  }
}

.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: normal;
  align-content: normal;
}

.flex-items:nth-child(1) {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  font-weight: 600;
  margin-bottom: 10px;
}

.icon-cell{
  vertical-align: top;
  color: #4110b7;
}

.home-card{
  box-sizing: border-box;
  width: 100%;
  border-radius: 20px;
  box-shadow: rgb(18 0 59 / 12%) 1px 4px 24px, rgb(18 0 59 / 8%) 0px 4px 8px;
  background-color: rgb(255, 255, 255);
  position: relative;
  padding: 16px; 
  height: 100%;
  margin-bottom: 20px;
  min-height:  157px;
  .row-1{
    display: flex;
    justify-content: space-between;
  }
  .row-2{
    display: flex;
    justify-content: start;
    
    margin-bottom: 10px;
  }
  .seprater{
    margin: 0 5px !important;
  }
  .row-3{
    display: flex;
    justify-content: space-between;
  }
  .cardHeading {
    margin: 0px;
    font-style: normal;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 15px;
    line-height: 22px;
    color: rgb(18, 0, 59) !important;
    font-weight: 700 !important;
    display: flex;
    flex-flow: column wrap;
  }
  .adviser-title {
    font-weight: 500;
}
  .amount{
    position: absolute;
    bottom: 27px;
    left: 15px;
    font-size: 28px;
    font-weight: 600;
  }
  .service-title{
    position: absolute;
    bottom: 54px;
    left: 15px;
    font-size: 16px;
    font-weight: 600;
  }
}

.Portfoliolist{
  .makeStyles-grid-33{
    padding: 10px 10px !important;
  }
  .SelectedPortfolio{
    border:  5px solid lightgreen;
  }
  .portfoliolistgrid{
    padding: 10px 15px !important;
  }
  #Portfoliogrid{
    padding: 10px 15px !important;
  }
}

@keyframes reveal {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.loader span {
  animation: reveal 1.5s ease-in-out infinite;
}